import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import UserRadarChart from "../UserRadarChart/UserRadarChart";
import UserComparisonTable from "../UserComparisonTable/UserComparisonTable";
import CardFraudReasons from "../CardFraudReasons/CardFraudReasons";
import "./user-comparison-modal.scss";

const UserComparisonModal = (props) => {
  // Check if there's fraud reasons, as the modal isn't in a Card component
  const renderFraudReasons =
    props?.cardFraudReasons?.reasons &&
    props?.cardFraudReasons?.reasons?.length > 0;

  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <div className={"user-comparison-modal-container"}>
        <CloseIcon className={"close-icon"} onClick={props.handleClose} />
        <div className={"modal-title"}>{`Top ${
          props?.data?.length - (props.initialUser ? 1 : 0)
        } ${props.titleAdjective} Users`}</div>
        <UserRadarChart
          initialUser={props.initialUser}
          data={props.data}
          chartDataLabels={props.chartDataLabels}
        />
        <UserComparisonTable
          initialUser={props.initialUser}
          tableDataLabels={props.tableDataLabels}
          userCtaButtonPrefix={props.userCtaButtonPrefix}
          data={props.data}
          tableDate={props.tableDate}
          linkedUsers={props.linkedUsers}
          handleCheckboxChange={props.handleCheckboxChange}
        />
        {renderFraudReasons && (
          <CardFraudReasons
            fraudReasonInfo={props.cardFraudReasons}
            checkReasonSelected={props.checkFraudReasonSelected}
            editSelectedReasons={props.editSelectedFraudReasons}
            selectedFraudReasons={props.selectedFraudReasons}
          />
        )}
      </div>
    </Modal>
  );
};

export default UserComparisonModal;
