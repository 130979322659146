import ABBR_MONTH_NAMES from "./constants/abbr_month_names.json";
import FULL_MONTH_NAMES from "./constants/full_month_names.json";
import DAYS_OF_THE_WEEK from "./constants/days_of_the_week.json";

// Checks for '\r's and/or '\n's in a string (aka new lines)
const NEW_LINE_REGEX = /\r?\n/;

class Format {
  /**
   * Returns string with first letter capitalized.
   * @param {String} string string to capitalize
   * @returns formatted string
   */
  static capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Returns a string to be used as appropriate suffix for a percentile.
   * @method
   * @param {Number} n
   * @returns {String}
   */
  static getPercentileSuffix = (n) => {
    // S.O: https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };

  /**
   * Makes title lowercase, removes instances of the word "unique".
   * @method
   * @param {String} text
   * @returns {String}
   */
  static getFormattedTitle(text) {
    return text.trim().replace("Unique", "");
  }

  /**
   * Takes an aggregated song ID and parses it, returning the track name and artist name in a list.
   * @param {String} songId aggregated song ID in the format "<track_name::artist_name::track_isrc>"
   * @returns an array where the first value should be the song name and the second should be the song artist
   */
  static getSongNameAndArtist(songId) {
    try {
      return songId.split("::").slice(0, -1);
    } catch (error) {
      return ["", ""];
    }
  }

  /**
   * Modifies a song ID to be returned in a URL-friendly format.
   * @param {String} songName the song name/title to format
   * @param {String} songArtist the song artist to format
   * @returns formatted song id string suitable for use in a URL
   */
  static formatSongUrl = (songName, songArtist) => {
    try {
      return [songName, songArtist]
        .map((text) =>
          this.formatSongText(text).toLowerCase().split(" ").join("-")
        )
        .join("-");
    } catch (error) {
      return "";
    }
  };

  /**
   * Modifies a string to be returned in a URL-friendly format.
   * @param {String} text the text to be formatted
   * @returns formatted text string suitable for use in a URL
   */
  static formatSongText = (text) => {
    // remove all special characters
    text = text.replace(/[$&=,:;@#|'<>.^*()%\\/+\-_]/g, "");

    // remove leading and trailing white space
    text = text.replace(/^\s+|\s+$/g, "");

    // replace all multi-spaces with single space
    text = text.replace(/\s\s+/g, " ");

    return text;
  };

  /**
   * Returns date string in the format "EEE (yyyy-MM-dd)".
   * @param {String} dateStr the string to format
   * @returns {String}
   */
  static formatDate = (dateStr) => {
    return `${new Date(dateStr).toUTCString().slice(0, 3)} (${dateStr})`;
  };

  /**
   * Returns a date string in format "MMM-dd".
   * @param {String} dateStr the date string to format
   * @returns formatted date string
   */
  static formatAbbrDate = (dateStr) => {
    try {
      const newDate = new Date(dateStr);
      const date = newDate.getUTCDate();
      const month = ABBR_MONTH_NAMES[newDate.getUTCMonth()];

      return `${month}-${date}`;
    } catch (error) {
      return dateStr;
    }
  };

  /**
   * Returns a date string in format "MMM dd, yyyy".
   * @param {String} dateStr the date string to format
   * @returns formatted date string
   */
  static formatReadableDate = (dateStr) => {
    try {
      const newDate = new Date(dateStr);
      const date = newDate.getUTCDate();
      const month = ABBR_MONTH_NAMES[newDate.getUTCMonth()];
      const year = newDate.getUTCFullYear();

      return `${month} ${date}, ${year}`;
    } catch (error) {
      return dateStr;
    }
  };

  /**
   * Returns a date string in format "MMMM yyyy".
   * @param {String} dateStr the date string to format
   * @returns formatted date string
   */
  static getFullMonthYear = (dateStr) => {
    try {
      const newDate = new Date(dateStr);
      return `${
        FULL_MONTH_NAMES[newDate.getUTCMonth()]
      } ${newDate.getUTCFullYear()}`;
    } catch (error) {
      return dateStr;
    }
  };

  /**
   * Returns date string in the ISO String format.
   * @param {String} dateStr the string to format
   * @returns {String}
   */
  static formatUTCDate = (dateStr) => {
    let newDateStr = dateStr;
    if (dateStr.includes("UTC")) {
      const dateArr = newDateStr.split(" ");
      newDateStr = `${dateArr[0]}T${dateArr[1]}Z`;
    }
    return newDateStr;
  };

  /**
   * Formats date object into EEEE, MMMM dd, yyyy string.
   * @param {Date} date the date object to format
   * @returns formatted date string
   */
  static formatLabelHistoryDateObject = (date) => {
    return date.toLocaleDateString("en-us", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  /**
   * Formats date object into as HH:mm string in 12 hr format.
   * @param {Date} dateTime the date object to format
   * @returns formatted datetime string
   */
  static formatInTwelveHourTime = (dateTime) => {
    try {
      return dateTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } catch (error) {
      return dateTime;
    }
  };

  /**
   * Generates the UTC day of the week for a given date string.
   * @param {String} dateStr the date string to format
   * @returns the day of the week
   */
  static getUTCDayOfTheWeek = (dateStr) => {
    return DAYS_OF_THE_WEEK[new Date(dateStr).getUTCDay()];
  };

  /**
   * Returns the string with a ":" at the end.
   * @param {String} rowName the string to format
   * @returns {String}
   */
  static formatTimelineTooltipRow = (rowName) => {
    return `${rowName}:`;
  };

  /**
   * Returns a pluralized version of the string fed into it, specifically the final word in the string.
   * @param {String} word the string to pluralize
   * @returns {String}
   */
  static pluralizeText = (word) => {
    const lastCharacter = word.charAt(word.length - 1);
    const pluralVersion =
      lastCharacter === "y"
        ? word.slice(0, -1) + "ies"
        : lastCharacter === "s"
        ? word + "es"
        : word + "s";

    return pluralVersion;
  };

  /**
   * Formats a raw percentage/percentile value to be displayed as a whole number between 0 and 100.
   * @param {Number} percentile raw percentage/percentile value between 0 and 1
   * @returns the formatted percentage/percentile value
   */
  static formatPercentile = (percentile) => {
    try {
      return Math.round(percentile * 100);
    } catch (error) {
      return percentile;
    }
  };

  /**
   * Formats a raw percentage value to be displayed as number with up to 2 decimals between 0 and 100,
   * with trailing 0s removed.
   * @param {Number} percentage raw percentage/percentile value between 0 and 1
   * @returns the formatted percentage value
   */
  static formatPercentage = (percentage) => {
    try {
      return parseFloat((percentage * 100).toFixed(2));
    } catch (error) {
      return percentage;
    }
  };

  /**
   * Formats a given numerical value to the specified number of decimal places.
   * @param {Number} value the value to format
   * @param {Number} decimals the number of decimal places to format the value to
   * @returns the formatted value as a string
   */
  static toDecimals = (value, decimals) => {
    try {
      return parseFloat(value.toFixed(decimals)).toString();
    } catch (error) {
      return value;
    }
  };

  /**
   * Returns a string of formatted number string value rounded
   * to n decimals and corresponding Large Number Suffix unit.
   * Examples:
   *   10000 -> 10K
   *   100678000 -> 100.7M
   *   2000000000 -> 2B
   * @param {Number} num the number to format
   * @param {Number} decimals the number of decimal places to format the value to
   * @returns the formatted value as a string
   */
  static formatLargeNumber = (num, decimals) => {
    let returnNum = 0;
    let suffix = "";

    const minValPerUnit = [
      { unit: "B", value: 1000000000 },
      { unit: "M", value: 1000000 },
      { unit: "K", value: 1000 },
    ];

    try {
      if (num === null || num === undefined || isNaN(num)) {
        // Go to the catch block and return the original value
        throw new Error("invalid value");
      }

      for (const unitData of minValPerUnit) {
        // Use the largest unit that is less than or equal to the value
        if (num >= unitData.value) {
          returnNum = num / unitData.value;
          suffix = unitData.unit;
          break;
        }
      }

      // If no suffix was assigned, then the number is less than 1,000
      // And should be returned with no suffix
      if (suffix === "") {
        returnNum = num;
      }

      // Format to desired decimal length
      returnNum = this.toDecimals(returnNum, decimals);

      return `${returnNum}${suffix}`;
    } catch (error) {
      return num;
    }
  };

  /**
   * Returns the string with all instances of unwanted strings replaced.
   * @param {String} originalStr string to search through/clean
   * @param {Array<String>} unwantedStr array of strings to check for and replace all instances of
   * @param {String} replacementStr string to replace found instances of unwanted strings
   * @returns {String}
   */
  static replaceAllInstances = (originalStr, unwantedStr, replacementStr) => {
    let cleanStr = originalStr;
    for (const string of unwantedStr) {
      const regex = new RegExp(string, "gi");
      cleanStr = cleanStr.replaceAll(regex, replacementStr);
    }
    return cleanStr;
  };

  /**
   * Splits a string into an array at all '\r' and '\n' characters.
   * @param {String} originalStr string to break into an array
   * @returns {Array}
   */
  static splitAtNewLine = (originalStr) => {
    return originalStr.split(NEW_LINE_REGEX);
  };

  /**
   * Filters a provided text string for named beatdapp partners, replacing them with generic alternatives.
   * @param {String} textToFilter text string to filter
   * @returns filtered text string
   */
  static filterNamedPartners = (textToFilter) => {
    try {
      // replace named dsp instances
      textToFilter = this.replaceAllInstances(
        textToFilter,
        ["napster", "napi", "nap"],
        "dsp"
      );

      // replace named device instances
      textToFilter = this.replaceAllInstances(
        textToFilter,
        ["sonos"],
        "speaker"
      );
    } catch (error) {
      // just return original string
    }

    return textToFilter;
  };
}

export default Format;
