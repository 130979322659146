import React, { useState, useEffect, useCallback } from "react";
import Loading from "../Loading/Loading";
import ProgressRing from "../ProgressRing/ProgressRing";
import Card from "../../containers/Card/Card";
import Format from "../../services/format";
import styles from "../../styles/constants.scss";
import axios from "../../config/axios";
import "./artist-suspicious-users.scss";

// percentage color boundaries
const COLOR_LOW_BOUNDARY = 5;
const COLOR_MED_BOUNDARY = 20;
const COLOR_HIGH_BOUNDARY = 50;

const OPEN_USER_COMPARISON_CTA_TEXT = "Sample suspicious users";

const ArtistSuspiciousUsers = ({
  artistId,
  overlayData,
  openUserComparisonModal,
  tableDate,
  partnerName,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(null);
  const [percentile, setPercentile] = useState(null);
  const [zScore, setZScore] = useState(null);
  const [susUsersTotal, setSusUsersTotal] = useState(null);
  const [usersTotal, setUsersTotal] = useState(null);
  const [warningColor, setWarningColor] = useState(styles.defaultMetricColor);

  const setPercentageWarningColor = (percentage) => {
    let color = styles.defaultMetricColor;

    if (percentage > COLOR_HIGH_BOUNDARY) {
      color = styles.darkRedMetricColor;
    } else if (percentage > COLOR_MED_BOUNDARY) {
      color = styles.redMetricColor;
    } else if (percentage > COLOR_LOW_BOUNDARY) {
      color = styles.yellowMetricColor;
    }

    setWarningColor(color);
  };

  const getArtistSuspiciousUsersData = useCallback(
    async (artistId, tableDate, partnerName) => {
      if (artistId !== null && artistId.length) {
        try {
          const res = await axios.post(
            "/get-artist-suspicious-users",
            {
              entityID: artistId,
              tableDate: tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          // Populate with data from the API
          const percentage = Format.formatPercentage(
            res.data.results.suspicious_user_ratio
          );
          setPercentage(percentage);
          setPercentile(
            Format.formatPercentile(
              res.data.results.suspicious_user_ratio_percentile
            )
          );
          setZScore(res.data.results.suspicious_user_ratio_z_score?.toFixed(2));
          setSusUsersTotal(res.data.results.suspicious_user_count);
          setUsersTotal(res.data.results.total_users);
          setPercentageWarningColor(percentage);
        } catch (error) {
          setPercentage(null);
          setPercentile(null);
          setZScore(null);
          setSusUsersTotal(null);
          setUsersTotal(null);
          setPercentageWarningColor(null);
        }

        // End loading state regardless
        setIsLoading(false);
      }
    },
    []
  );

  // Update component data on date or id change
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setIsLoading(true);
      getArtistSuspiciousUsersData(artistId, tableDate, partnerName);
    }

    return () => {
      abort = true;
    };
  }, [artistId, tableDate, partnerName, getArtistSuspiciousUsersData]);

  // check to see if there is overlay data to display
  const overlayDataAvailable = overlayData && overlayData.length > 0;

  return (
    <div className={"artist-suspicious-users-container"}>
      {!isLoading ? (
        <>
          <div className={"suspicious-users-header"}>{"Suspicious Users"}</div>
          <div className={"suspicious-users-left-section"}>
            <div className={"suspicious-users-left-column"}>
              <div className={"progress-ring-container"}>
                <ProgressRing percentage={percentage} color={warningColor} />
              </div>
              <div className={"suspicious-users-secondary-metric"}>
                {percentile !== null && percentile !== undefined ? (
                  <>
                    {percentile}
                    <sup>{Format.getPercentileSuffix(percentile)}</sup>
                  </>
                ) : (
                  "n/a"
                )}
                {" percentile"}
              </div>
              <div className={"suspicious-users-secondary-metric"}>
                {`z-score: ${
                  zScore !== null && zScore !== undefined ? zScore : "n/a"
                }`}
              </div>
            </div>
          </div>
          <div className={"suspicious-users-right-section"}>
            <div className={"suspicious-users-right-column"}>
              <div
                className={"suspicious-users-total"}
                style={{ color: warningColor }}
              >
                {susUsersTotal !== null && susUsersTotal !== undefined
                  ? susUsersTotal
                  : "n/a"}
              </div>
              <div className={"users-total"}>{`/${
                usersTotal !== null && usersTotal !== undefined
                  ? usersTotal
                  : ""
              } total users`}</div>
            </div>
          </div>
          <div className={"suspicious-users-btn-container"}>
            <button
              className={
                overlayDataAvailable
                  ? "suspicious-users-btn"
                  : "suspicious-users-btn disabled"
              }
              onClick={openUserComparisonModal}
              disabled={!overlayDataAvailable}
            >
              {OPEN_USER_COMPARISON_CTA_TEXT}
            </button>
          </div>
        </>
      ) : (
        <div className={"suspicious-users-loader"}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Card(ArtistSuspiciousUsers);
