const TIMELINE_CONSTANTS = {
  subcategoriesToRegexAnonymize: ["device_type", "device_model"],
  napster: {
    selectFilterOptions: [
      { label: "Account Type", field: "account_type" },
      { label: "Artist", field: "track_artist" },
      { label: "Country", field: "CountryName" },
      { label: "Date", field: "eventdate" },
      { label: "Device Type", field: "device_type" },
      { label: "Distributor", field: "track_distributor" },
      { label: "Duration", field: "stream_duration" },
      { label: "Label", field: "track_label" },
      { label: "Release Artist", field: "release_artist" },
      { label: "Release Title", field: "release_title" },
      { label: "Title", field: "track_title" },
      { label: "Track Isrc", field: "track_isrc" },
    ],
    fieldsWithSubcategories: [
      "CountryName",
      "track_distributor",
      "track_label",
      "account_type",
      "stream_duration",
      "device_type",
    ],
    typeSpecificLogic: {
      defaultCategoryFilter: "track_isrc",
      dateFilterField: "eventdate",
      timestampField: "stream_timestamp_start",
      toolTipColumnCount: 2,
      tooltipFilterFields: ["user_id", "country_code"],
    },
  },
  amplitude: {
    selectFilterOptions: [
      { label: "City", field: "city" },
      { label: "Country Code", field: "country_code" },
      { label: "Device ID", field: "device_id" },
      { label: "Device Model", field: "device_model" },
      { label: "Device Type", field: "device_type" },
      { label: "Event Type", field: "event_type" },
      { label: "Language", field: "language" },
      { label: "IP Hash", field: "hash_ip" },
    ],
    fieldsWithSubcategories: [
      "country_code",
      "device_type",
      "device_model",
      "city",
      "event_type",
    ],
    typeSpecificLogic: {
      defaultCategoryFilter: "event_type",
      dateFilterField: "eventdate",
      timestampField: "event_timestamp",
      toolTipColumnCount: 1,
      tooltipFilterFields: ["user_id", "user_creation_time"],
    },
  },
};

export { TIMELINE_CONSTANTS };
