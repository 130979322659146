import React, { useState, useEffect, useCallback } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Card from "../../containers/Card/Card";
import Loading from "../Loading/Loading";
import Format from "../../services/format";
import styles from "../../styles/constants.scss";
import axios from "../../config/axios";
import "./artist-play-count.scss";

// area/line chart dimensions
const CHART_MARGINS = {
  top: 10,
  right: 0,
  left: 0,
  bottom: 0,
};
const Y_AXIS_CHART_SPACE_MULTIPLIER = 1.1;

const TOTAL_PLAYS_KEY = "total_plays_diff";

// chart legend properties
const LEGEND_HEIGHT = 36;
const LEGEND_RENDER_ORDER = [
  {
    title: "Monetized Plays",
    key: "monetized_plays",
    stroke: styles.monetizedPlaysColor,
    fill: styles.monetizedPlaysColor,
    stack: true,
  },
  {
    title: "Total Plays",
    key: TOTAL_PLAYS_KEY,
    stroke: styles.totalPlaysColor,
    fill: styles.totalPlaysColor,
    stack: true,
  },
  {
    title: "Suspicious Plays",
    key: "suspicious_plays",
    stroke: styles.suspiciousPlaysColor,
    fill: "transparent", // we want to draw a straight line
    stack: false,
  },
];
const LEGEND_KEY_ORDER = [
  "suspicious_plays",
  "monetized_plays",
  TOTAL_PLAYS_KEY,
];

const ArtistPlayCount = ({
  artistId,
  startDate,
  endDate,
  partnerName,
  xAxisKey,
}) => {
  const [renderMonthView, setRenderMonthView] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [loading, setLoading] = useState(false);

  const renderDateHeader = (startDate, endDate) => {
    try {
      return renderMonthView
        ? `Month of ${Format.getFullMonthYear(startDate)}`
        : `${Format.formatReadableDate(
            startDate
          )} - ${Format.formatReadableDate(endDate)}`;
    } catch (error) {
      return "";
    }
  };

  const getArtistPlayCountData = useCallback(
    async (artistId, tableDate, partnerName) => {
      if (artistId !== null && artistId.length) {
        try {
          const res = await axios.post(
            "/get-comparative-count",
            {
              entityType: "artist",
              entityID: artistId,
              tableDate: tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          const results = res.data.results;

          // Populate with data from the API
          setDefaultData(results.default);
          setMonthData(results.month);
        } catch (error) {
          setDefaultData([]);
          setMonthData([]);
        }

        // End loading state regardless
        setLoading(false);
      }
    },
    []
  );

  // Update component data on date or id change
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setLoading(true);
      getArtistPlayCountData(artistId, startDate, partnerName);
    }

    return () => {
      abort = true;
    };
  }, [artistId, startDate, partnerName, getArtistPlayCountData]);

  const sumTotalPlays = (payload, unMonetizedPlays) => {
    // unmonetized plays should be summed with monetized plays to get total
    let total = unMonetizedPlays;
    const monetizedPlays = payload.find(
      (option) => option.dataKey === "monetized_plays"
    )?.value;

    if (monetizedPlays) {
      total = total + monetizedPlays;
    }

    return total;
  };

  const renderToolTip = ({ payload, label }) => {
    return (
      <div className={"artist-play-count-tooltip"}>
        <div className={"tool-tip-header"}>{Format.formatDate(label)}</div>
        {payload &&
          payload
            .sort(
              (a, b) =>
                // reverse order for tool tip
                LEGEND_KEY_ORDER.indexOf(b.dataKey) -
                LEGEND_KEY_ORDER.indexOf(a.dataKey)
            )
            .map((entry, index) => (
              <div
                className={
                  entry.dataKey === TOTAL_PLAYS_KEY
                    ? "total-plays-row tool-tip-row"
                    : "tool-tip-row"
                }
                key={`item-${index}`}
              >
                <div className={"tool-tip-row-value"}>
                  {
                    LEGEND_RENDER_ORDER.find(
                      (option) => entry.dataKey === option.key
                    ).title
                  }
                </div>
                <div className={"tool-tip-row-value"}>
                  {entry.dataKey === TOTAL_PLAYS_KEY
                    ? sumTotalPlays(payload, entry.value)
                    : entry.value}
                </div>
              </div>
            ))}
      </div>
    );
  };

  const renderLegend = ({ payload }) => {
    return (
      <div className={"artist-play-count-legend"}>
        {payload
          .sort(
            (a, b) =>
              LEGEND_KEY_ORDER.indexOf(a.dataKey) -
              LEGEND_KEY_ORDER.indexOf(b.dataKey)
          )
          .map((entry, index) => (
            <span className={"legend-option"} key={`item-${index}`}>
              <div
                className={"legend-option-icon"}
                style={{ backgroundColor: entry.color }}
              />
              <span>
                {
                  LEGEND_RENDER_ORDER.find(
                    (option) => entry.value === option.key
                  ).title
                }
              </span>
            </span>
          ))}
      </div>
    );
  };

  return (
    <div className={"artist-play-count-wrapper"}>
      <div className={"artist-play-count-header"}>Artist Play Count</div>
      <div className={"artist-play-count-sub-header"}>
        <div>{renderDateHeader(startDate, endDate)}</div>
        <div className={"month-view-toggle-container"}>
          <div className={"month-view-sub-container month-view-label"}>
            Display Full Month
          </div>
          <div className={"month-view-sub-container"}>
            <label className={"month-view-toggle"}>
              <input
                type={"checkbox"}
                checked={renderMonthView}
                onChange={() => setRenderMonthView(!renderMonthView)}
              />
              <span className={"slider"}></span>
            </label>
          </div>
        </div>
      </div>
      <div className={"artist-play-count-chart-container"}>
        <div
          className={`artist-play-count-loader-and-message-container${
            !loading ? " loaded" : ""
          }`}
        >
          {loading ? (
            <Loading />
          ) : (
            <span className={"artist-play-count-no-data-message"}>
              {(renderMonthView
                ? monthData?.length === 0
                : defaultData?.length === 0) &&
                `No ${renderDateHeader(startDate, endDate)} data available`}
            </span>
          )}
        </div>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <AreaChart
            className={"artist-play-count-chart"}
            data={renderMonthView ? monthData : defaultData}
            margin={CHART_MARGINS}
          >
            <CartesianGrid strokeDasharray={"3 3"}></CartesianGrid>
            <XAxis
              dataKey={xAxisKey}
              tickFormatter={(tick) => Format.formatAbbrDate(tick)}
            />
            <YAxis
              type={"number"}
              domain={[
                "auto",
                (dataMax) => dataMax * Y_AXIS_CHART_SPACE_MULTIPLIER,
              ]}
            />
            <Tooltip content={renderToolTip} />
            <Legend
              verticalAlign={"bottom"}
              iconType={"circle"}
              height={LEGEND_HEIGHT}
              content={renderLegend}
            />
            {LEGEND_RENDER_ORDER.map((option) => (
              <Area
                type={"monotone"}
                dataKey={option.key}
                stackId={option.stack ? "1" : null}
                stroke={option.stroke}
                fill={option.fill}
                key={option.key}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Card(ArtistPlayCount);
