import React from "react";
import InfoTip from "../InfoTip/InfoTip";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import "./user-comparison-table.scss";

const CTA_BUTTON_TITLE_SUFFIX = " User";
const LINK_USER_LABEL = "Link User";

const INFO_TEXT =
  "Checking this box will link users that show strong similarities. However, each user still needs to be investigated and labelled individually. ";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  "&.Mui-checked": {
    color: "#772EDD",
  },
}));

const UserComparisonModal = (props) => {
  return (
    <TableContainer className={"user-comparison-table-container"}>
      <Table
        className={`user-comparison-table${
          props.initialUser ? " with-initial-user" : ""
        }`}
      >
        <TableHead>
          <TableRow>
            {/* Empty first table cell */}
            <StyledTableCell />
            {props.data.map((user, i) => (
              <StyledTableCell
                className={"user-column-header"}
                align={"left"}
                key={user.user_id ?? i}
              >
                {props.initialUser && i === 0
                  ? "Initial User"
                  : `User ${props.initialUser ? i : i + 1}`}
                <div className={"user-id-field"}>{`\n${user.user_id}`}</div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableDataLabels.map((metric) => (
            <StyledTableRow key={metric.title}>
              <StyledTableCell align={"left"}>
                <b>{metric.title}</b>
              </StyledTableCell>
              {props.data.map((user, i) => {
                return (
                  <StyledTableCell align={"left"} key={user.user_id ?? i}>
                    {user[metric.label] === null ? "N/A" : user[metric.label]}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <StyledTableCell>{/* blank */}</StyledTableCell>
            {props.data.map((user, index) =>
              // ignore the initial user
              props.initialUser && index === 0 ? (
                <StyledTableCell key={user.user_id ?? index}>
                  {/* blank */}
                </StyledTableCell>
              ) : (
                <StyledTableCell align={"left"} key={user.user_id ?? index}>
                  <Link
                    to={`/user/${user.user_id}/${props.tableDate}`}
                    target={"_blank"}
                  >
                    <button className={"user-link-button"}>
                      {props.userCtaButtonPrefix + CTA_BUTTON_TITLE_SUFFIX}
                    </button>
                  </Link>
                </StyledTableCell>
              )
            )}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <div className={"link-user-info"}>
                <span>{`${LINK_USER_LABEL} `}</span>
                <InfoTip
                  description={INFO_TEXT}
                  tooltipWidth={150}
                  bottomAlign={true}
                  arrow={false}
                />
              </div>
            </StyledTableCell>
            {props.data.map((user, index) =>
              // ignore the initial user
              props.initialUser && index === 0 ? (
                <StyledTableCell key={user.user_id ?? index}>
                  {/* blank */}
                </StyledTableCell>
              ) : (
                <StyledTableCell align={"left"} key={user.user_id ?? index}>
                  <StyledCheckbox
                    onChange={() => props.handleCheckboxChange(user.user_id)}
                    name={user.user_id}
                    checked={props.linkedUsers.includes(user.user_id)}
                  />
                  <span
                    className={"user-checkbox-label"}
                    onClick={() => props.handleCheckboxChange(user.user_id)}
                  >
                    {LINK_USER_LABEL}
                  </span>
                </StyledTableCell>
              )
            )}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserComparisonModal;
