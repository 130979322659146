import { useState, useEffect, useCallback } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import InfoTip from "../InfoTip/InfoTip";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "../../containers/Card/Card";
import Loading from "../Loading/Loading";
import Format from "../../services/format";
import styles from "../../styles/constants.scss";
import axios from "../../config/axios";
import "./total-plays.scss";

const ERROR_MESSAGE = "Something went wrong with data retrieval.";
const TOOL_TIP_TEXT =
  "Monetized plays are streams, normally at least 30s long that are valid for royalty payments. Most human users typically have a range of 0-95% of monetized plays / total plays.";
const TOOL_TIP_FONT_SIZE = "12px";
const DEFAULT_FONT_SIZE = 5;
const SM_PROGRESS_BAR_MAX_WIDTH = "200px";

const CustomProgressBar = styled(LinearProgress)(() => ({
  height: "8px",
  width: "100%",
  borderRadius: "20px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#cfc0e3", // $color-light-purple in 'constants'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "20px",
    backgroundColor: "#772edd",
  },
}));

const TotalPlays = ({
  scoreLimits,
  entityType,
  entityId,
  partnerName,
  tableDate,
}) => {
  // mui lg breakpoint
  const isSmallScreen = useMediaQuery("(max-width:1199px)");
  const [loadFailure, setLoadFailure] = useState(false);
  const [monetizedPlays, setMonetizedPlays] = useState(null);
  const [totalPlays, setTotalPlays] = useState(null);
  const [zScore, setZScore] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [formattedPercentile, setFormattedPercentile] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [renderProgressInfo, setRenderProgressInfo] = useState(false);

  // To avoid zero as a falsy value.
  const zScoreCondition = zScore !== undefined && zScore !== null;

  const getTotalPlaysData = useCallback(async () => {
    if (entityId) {
      setIsLoading(true);

      try {
        const res = await axios.post(
          "/get-total-plays",
          {
            entityType,
            entityID: entityId,
            tableDate,
            provider: partnerName,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tk")}`,
            },
          }
        );

        const data = res.data.results;

        setMonetizedPlays(data?.revenue_generating_streams);
        setTotalPlays(data?.total_plays);
        setZScore(data?.revenue_generating_streams_z_score);
        setPercentage(data?.revenue_generating_percentage);
        setFormattedPercentile(
          data?.revenue_generating_streams_percentile
            ? Math.round(data.revenue_generating_streams_percentile * 100)
            : null
        );
      } catch (error) {
        setLoadFailure(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [entityType, entityId, tableDate, partnerName]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const fetchData = async () => {
        await getTotalPlaysData();
      };

      // reset state first
      setMonetizedPlays(null);
      setTotalPlays(null);
      setZScore(null);
      setPercentage(null);
      setFormattedPercentile(null);
      setLoadFailure(false);

      fetchData();
    }

    return () => {
      abort = true;
    };
  }, [entityType, entityId, tableDate, getTotalPlaysData]);

  const getScoreColor = (score, scoreLimits) => {
    let color = styles.defaultMetricColor;

    if (!scoreLimits || !scoreLimits.high || !scoreLimits.low) {
      return color;
    }

    if (score >= scoreLimits.high) {
      color = styles.redMetricColor;
    } else if (score >= scoreLimits.low) {
      color = styles.yellowMetricColor;
    }

    return color;
  };

  const getFontSize = (textLength) => {
    return textLength >= DEFAULT_FONT_SIZE
      ? `${DEFAULT_FONT_SIZE - 1}rem`
      : `${DEFAULT_FONT_SIZE}rem`;
  };

  return (
    <div className={"total-plays-card"}>
      <div
        className={"total-plays-card-content"}
        style={{
          alignItems: isLoading || isSmallScreen ? "center" : "flex-start",
        }}
      >
        {!loadFailure && !isLoading && (
          <>
            <div className={"total-plays-header-title"}>
              <span>{"Monetized Plays"}</span>
              <InfoTip
                description={TOOL_TIP_TEXT}
                fontSize={TOOL_TIP_FONT_SIZE}
                tooltipWidth={200}
                arrow={false}
              />
            </div>
            <div
              className={"monetized-plays-score"}
              style={{
                color: getScoreColor(totalPlays, scoreLimits),
                fontSize: monetizedPlays
                  ? getFontSize(monetizedPlays.toFixed().length)
                  : `${DEFAULT_FONT_SIZE}rem`,
              }}
            >
              {monetizedPlays ?? 0}
            </div>
            {!isNaN(formattedPercentile) && (
              <div className={"total-plays-text"}>
                {
                  <p>
                    {formattedPercentile ?? 0}
                    <sup>{Format.getPercentileSuffix(formattedPercentile)}</sup>
                    {" percentile"}
                  </p>
                }
              </div>
            )}
            <div className={"total-plays-text"}>
              {`z-score: ${zScoreCondition ? zScore.toFixed(2) : "N/A"}`}
            </div>
            <CustomProgressBar
              className={"total-plays-progress-bar"}
              variant={"determinate"}
              value={percentage * 100}
              onMouseMove={(e) => {
                setCursorPosition({
                  x: e.clientX,
                  y: e.clientY,
                });
              }}
              onMouseOver={() => setRenderProgressInfo(true)}
              onMouseLeave={() => setRenderProgressInfo(false)}
              style={{
                maxWidth: isSmallScreen ? SM_PROGRESS_BAR_MAX_WIDTH : "100%",
              }}
            />
            <div
              className={"progress-bar-tool-tip"}
              style={{
                display: renderProgressInfo ? "block" : "none",
                top: cursorPosition.y,
                left: cursorPosition.x,
              }}
            >
              <span>{"Monetized Plays/Total Plays: "}</span>
              <br />
              <span>{`${+(percentage * 100).toFixed(2)}%`}</span>
            </div>
            <div className={"total-plays-header-title"}>{"Total Plays"}</div>
            <div
              className={"total-plays-score"}
              style={{
                color: getScoreColor(totalPlays, scoreLimits),
              }}
            >
              {totalPlays ?? 0}
            </div>
          </>
        )}
        {loadFailure && <p>{ERROR_MESSAGE}</p>}
        {!loadFailure && isLoading && <Loading className={"loader"} />}
      </div>
    </div>
  );
};

export default Card(TotalPlays);
