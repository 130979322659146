import React, { useState, useEffect } from "react";
import UseProfile from "../UseProfile/UseProfile";
import Grid from "@mui/material/Grid";
import DashboardView from "../../containers/DashboardView/DashboardView";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import DashboardFooter from "../../components/DashboardFooter/DashboardFooter";
import TotalPlays from "../../components/TotalPlays/TotalPlays";
import StackedAreaChart from "../../components/StackedAreaChart/StackedAreaChart";
import DualTimeline from "../../components/DualTimelines/DualTimelines";
import UserDecisionBox from "../../components/UserDecisionBox/UserDecisionBox";
import PercentileMetrics from "../../components/PercentileMetrics/PercentileMetrics";
import SimilarUsersButton from "../../components/SimilarUsersButton/SimilarUsersButton";
import UserComparisonModal from "../../components/UserComparisonModal/UserComparisonModal";
import OverlappingPlaysCard from "../../components/OverlappingPlaysCard/OverlappingPlaysCard";

import METRIC_LIMITS from "../../config/limits";
import HANKS_TIPS_TEXT from "../../config/hanks_tips.json";
import { CARD_FRAUD_REASONS } from "../../config/user_fraud_reasons";

const {
  USER_COMP_TABLE_METRICS,
  USER_RADAR_COMP_METRICS,
} = require("../../config/user_comp_metrics_user");

const PIE_CHART_TOP_ARTISTS_ID = "top_25_track_artists";

const ENTITY_TYPE = "user";

const UserProfile = (props) => {
  const {
    checkFraudReasonSelected,
    dataWeeks,
    editLinkedUsers,
    editSelectedFraudReasons,
    endDate,
    entityId,
    generateMetricCards,
    generatePieChart,
    handleDateRangeChange,
    handlePartnerChange,
    linkedUsers,
    loadPage,
    metricCardData,
    partnerName,
    pieChartsData,
    resetPage,
    selectedFraudReasons,
    setUserComparisonModalVisible,
    startDate,
    userComparisonModalData,
    userComparisonModalVisible,
  } = UseProfile({
    ogEntityId: props.match.params.id,
    entityType: ENTITY_TYPE,
    tableDateFromUrl: props.match.params.table_date,
  });

  const [renderTimeline, setRenderTimeline] = useState(true);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    // And reset whether the timeline should render on provider change
    if (!abort) {
      setRenderTimeline(true);
    }

    return () => {
      abort = true;
    };
  }, [partnerName]);

  return loadPage ? (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <DashboardHeader
          entityId={entityId}
          entityType={"user"}
          partnerName={partnerName}
          startDate={startDate}
          endDate={endDate}
          dataWeeks={dataWeeks}
          handleDateRangeChange={handleDateRangeChange}
          handlePartnerChange={handlePartnerChange}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12}>
            <TotalPlays
              entityId={entityId}
              entityType={"user"}
              partnerName={partnerName}
              tableDate={startDate}
              scoreLimits={METRIC_LIMITS.total_plays}
              cardFraudReasons={CARD_FRAUD_REASONS.totalPlays}
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={9}>
        <StackedAreaChart
          entityId={entityId}
          entityType={"user"}
          partnerName={partnerName}
          startDate={startDate}
          endDate={endDate}
          tableDate={startDate}
          dataWeeks={dataWeeks}
          cardFraudReasons={CARD_FRAUD_REASONS.stackedAreaChart}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
        />
      </Grid>
      {renderTimeline && (
        <Grid item xs={12}>
          <DualTimeline
            entityType={"user"}
            entityId={entityId}
            partnerName={partnerName}
            startDate={startDate}
            endDate={endDate}
            tableDate={startDate}
            dataWeeks={dataWeeks}
            tipText={HANKS_TIPS_TEXT.dualTimeline}
            cardFraudReasons={CARD_FRAUD_REASONS.dualTimeline}
            setRenderTimeline={setRenderTimeline}
            checkFraudReasonSelected={checkFraudReasonSelected}
            editSelectedFraudReasons={editSelectedFraudReasons}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <PercentileMetrics
          entityType={"user"}
          entityId={entityId}
          tableDate={startDate}
          partnerName={partnerName}
          title={"Percentile Metrics"}
          titleLength={330}
          cardFraudReasons={CARD_FRAUD_REASONS.percentileMetrics}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {metricCardData.length > 0 &&
            generateMetricCards(metricCardData, CARD_FRAUD_REASONS)}
          <Grid item xs={12} sm={4}>
            <OverlappingPlaysCard
              entityType={"user"}
              entityId={entityId}
              partnerName={partnerName}
              tableDate={startDate}
              scoreLimits={
                METRIC_LIMITS["avg_user_overlapping_plays_ratio"] ?? null
              }
              cardFraudReasons={
                CARD_FRAUD_REASONS.metricCards["overlapping_plays"]
              }
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
            />
          </Grid>
        </Grid>
      </Grid>
      {pieChartsData.length > 0 &&
        pieChartsData.map((chartData) => {
          // TODO: render loading spinner
          return (
            <Grid item xs={4} key={`pie-chart-${chartData.id}`}>
              {generatePieChart({
                chartId: chartData.id,
                title: chartData.title,
                cardFraudReasons: CARD_FRAUD_REASONS.pieChart[chartData.id],
                deviceTypeSource: chartData.device_type_source,
                minHeight: 510,
                tipText:
                  chartData.id === PIE_CHART_TOP_ARTISTS_ID
                    ? HANKS_TIPS_TEXT.topArtists
                    : null,
              })}
            </Grid>
          );
        })}
      <Grid container item xs={12} justifyContent={"center"}>
        <Grid item>
          <SimilarUsersButton
            data={userComparisonModalData}
            partnerName={partnerName}
            handleOnClick={() => setUserComparisonModalVisible(true)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <UserDecisionBox
          provider={partnerName}
          dsp={partnerName}
          entityId={entityId}
          tableDate={startDate}
          linkedUsers={linkedUsers}
          editLinkedUsers={editLinkedUsers}
          handleFormSubmission={resetPage}
          selectedFraudReasons={selectedFraudReasons}
          editSelectedFraudReasons={editSelectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <DashboardFooter />
      </Grid>
      <UserComparisonModal
        open={userComparisonModalVisible}
        partnerName={partnerName}
        titleAdjective={"Similar"}
        initialUser={true}
        userCtaButtonPrefix={"Investigate"}
        tableDataLabels={USER_COMP_TABLE_METRICS}
        chartDataLabels={USER_RADAR_COMP_METRICS}
        data={userComparisonModalData}
        tableDate={startDate}
        linkedUsers={linkedUsers}
        handleCheckboxChange={editLinkedUsers}
        handleClose={() => setUserComparisonModalVisible(false)}
      />
    </Grid>
  ) : (
    <></>
  );
};

export default DashboardView(UserProfile);
