import React, { useState, useEffect, useCallback } from "react";
import Format from "../../services/format";
import Loading from "../Loading/Loading";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Card from "../../containers/Card/Card";
import styles from "../../styles/constants.scss";
import axios from "../../config/axios";
import "./artist-top-songs.scss";

// area/line chart dimensions
const CHART_MARGINS = {
  top: 5,
  right: 30,
  left: 25,
  bottom: 5,
};
const BAR_SIZE = 10;

// Minimum bar size must be at least 5% of the largest bar
const MINIMUM_BAR_RATIO = 0.05;

const BAR_RENDER_OPTIONS = [
  {
    title: "Suspicious Plays",
    key: "suspicious_plays",
    fill: styles.suspiciousPlaysColor,
    stack: "1",
  },
  {
    title: "Monetized Plays",
    key: "monetized_plays",
    fill: styles.monetizedPlaysColor,
    stack: "2",
  },
  {
    title: "Total Plays",
    key: "suspicious_plays_total_diff",
    fill: styles.totalPlaysColor,
    stack: "1",
  },
  {
    title: "Total Plays",
    key: "monetized_plays_total_diff",
    fill: styles.totalPlaysColor,
    stack: "2",
  },
];

const Y_AXIS_MAX_CHAR = 17;
const Y_AXIS_WIDTH = 130;
const Y_AXIS_FONT_SIZE = "16px";
const AXIS_LABEL_COLOR = styles.defaultMetricColor;

const LEGEND_KEY_ORDER = [
  "suspicious_plays",
  "monetized_plays",
  "suspicious_plays_total_diff",
];

const TOOL_TIP_KEYS = [
  {
    title: "Suspicious Plays",
    key: "suspicious_plays",
    key_pair: "suspicious_plays_total_diff",
    fill: styles.legiblePurpleColor,
  },
  {
    title: "Monetized Plays",
    key: "monetized_plays",
    key_pair: "monetized_plays_total_diff",
    fill: styles.monetizedPlaysColor,
  },
];

const NO_DATA_TO_RENDER_MESSAGE = "Unable to render chart due to missing data";
const SKEWED_BAR_MESSAGE =
  "* The following bar has been visually altered to enhance visibility - and is not an accurately scaled representation.";

const CustomYAxisTick = ({ y, payload }) => {
  const tickFormatter = (value) => {
    try {
      if (!value) return "N/A";
      if (value.length <= Y_AXIS_MAX_CHAR) return value;
      return `${value.substring(0, Y_AXIS_MAX_CHAR - 3)}...`;
    } catch (error) {
      return "N/A";
    }
  };

  return (
    <g transform={`translate(${0},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor={"start"}
        fill={AXIS_LABEL_COLOR}
        fontSize={Y_AXIS_FONT_SIZE}
      >
        {tickFormatter(payload.value)}
      </text>
    </g>
  );
};

const ArtistTopSongs = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const getArtistTopSongsData = useCallback(
    async (artistId, tableDate, partnerName) => {
      if (artistId !== null && artistId.length) {
        try {
          const res = await axios.post(
            "/get-top-comparative-count",
            {
              entityType: "artist",
              entityID: artistId,
              tableDate: tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          const results = res.data.results;

          const minBarValue =
            (results[0][TOOL_TIP_KEYS[0].key] +
              results[0][TOOL_TIP_KEYS[0].key_pair]) *
            MINIMUM_BAR_RATIO;

          results.forEach((item) => {
            let replaced = false;

            TOOL_TIP_KEYS.forEach((keyType) => {
              const totalPlays = item[keyType.key] + item[keyType.key_pair];

              if (totalPlays < minBarValue) {
                replaced = true;
                let playsRatio = item[keyType.key] / totalPlays;

                item[`original_${keyType.key}`] = item[keyType.key];
                item[`original_${keyType.key_pair}`] = item[keyType.key_pair];

                item[keyType.key] = minBarValue * playsRatio;
                item[keyType.key_pair] = minBarValue - item[keyType.key];
              }
            });

            item.replaced = replaced;
            if (replaced) item.name = `* ${item.name}`;
          });

          setData(results);
        } catch (error) {
          setData(null);
        }

        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setIsLoading(true);
      getArtistTopSongsData(props.artistId, props.tableDate, props.partnerName);
    }

    return () => {
      abort = true;
    };
  }, [
    props.artistId,
    props.tableDate,
    props.partnerName,
    getArtistTopSongsData,
  ]);

  const renderToolTip = ({ payload, label }) => {
    if (!payload) {
      return <></>;
    }
    let isSkewed;

    if (payload[0]?.payload.replaced) label = label?.replace(/^\*/, "");

    return (
      <div className={"artist-top-songs-tool-tip"}>
        <div className={"tool-tip-header"}>{label}</div>
        {TOOL_TIP_KEYS.map((option) => {
          const keyData = payload.find(
            (optionData) => optionData.dataKey === option.key
          );

          const totalPlaysData = payload.find(
            (optionData) => optionData.dataKey === option.key_pair
          );

          // use `original` value if it exists
          const keyValue = keyData?.payload[`original_${option.key}`]
            ? keyData?.payload[`original_${option.key}`]
            : keyData?.payload[option.key];
          const totalPlaysValue = totalPlaysData?.payload[
            `original_${option.key_pair}`
          ]
            ? totalPlaysData?.payload[`original_${option.key_pair}`]
            : totalPlaysData?.payload[option.key_pair];

          // setup a boolean to see if this tooltip should mention that the bar was modified
          isSkewed =
            totalPlaysData?.payload[`original_${option.key_pair}`] !==
            undefined;

          const totalPlaysSum =
            keyValue >= 0 && totalPlaysValue >= 0
              ? keyValue + totalPlaysValue
              : 0;

          return (
            <div className={"tool-tip-row"} key={option.key}>
              <span className={"tool-tip-section"}>{`${option.title}:`}</span>
              <span
                className={"tool-tip-section"}
                style={{ color: option.fill }}
              >
                {keyValue && totalPlaysSum
                  ? `${Format.formatPercentage(keyValue / totalPlaysSum)}%`
                  : ""}
              </span>
              {keyValue >= 0 && totalPlaysSum >= 0 && (
                <>
                  <span className={"tool-tip-plays-value"}>{keyValue}</span>
                  {totalPlaysSum !== keyValue && (
                    <span>{`/${totalPlaysSum}`}</span>
                  )}
                </>
              )}
            </div>
          );
        })}
        {isSkewed ? (
          <span className={"notice"}>{SKEWED_BAR_MESSAGE}</span>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderLegend = () => {
    return (
      <div className={"artist-play-count-legend"}>
        {LEGEND_KEY_ORDER.map((entry, index) => (
          <span className={"legend-option"} key={`item-${index}`}>
            <div
              className={"legend-option-icon"}
              style={{
                backgroundColor: BAR_RENDER_OPTIONS.find(
                  (option) => entry === option.key
                ).fill,
              }}
            />
            <span>
              {BAR_RENDER_OPTIONS.find((option) => entry === option.key).title}
            </span>
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className={"artist-top-songs-wrapper"}>
      {!isLoading && data !== null ? (
        <>
          <div className={"artist-top-songs-header"}>
            Most Played Songs This Week
          </div>
          <div className={"artist-top-songs-chart-container"}>
            <ResponsiveContainer width={"100%"} height={"100%"}>
              <BarChart
                data={data} // assume data has already been sorted
                layout={"vertical"}
                barSize={BAR_SIZE}
                margin={CHART_MARGINS}
              >
                <CartesianGrid strokeDasharray={"3 3"} />
                <XAxis
                  type={"number"}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: AXIS_LABEL_COLOR }}
                />
                <YAxis
                  type={"category"}
                  dataKey={props.yAxisKey}
                  axisLine={false}
                  tickLine={false}
                  width={Y_AXIS_WIDTH}
                  tick={<CustomYAxisTick />}
                />
                <Tooltip
                  cursor={false}
                  content={renderToolTip}
                  wrapperStyle={{ zIndex: 1 }}
                />
                <Legend content={renderLegend} />
                {BAR_RENDER_OPTIONS.map((option) => (
                  <Bar
                    dataKey={option.key}
                    stackId={option.stack}
                    fill={option.fill}
                    key={option.key}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <div className={"artist-top-songs-loader-or-error-message"}>
          {isLoading ? (
            <div className={"artist-top-songs-loader"}>
              <Loading />
            </div>
          ) : (
            <div className={"artist-top-songs-error"}>
              {NO_DATA_TO_RENDER_MESSAGE}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Card(ArtistTopSongs);
