import React, { Component } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Index } from "flexsearch";
import Logout from "../../components/Logout/Logout";
import LabelHistorySearch from "../../components/LabelHistorySearch/LabelHistorySearch";
import LabelHistoryTable from "../../components/LabelHistoryTable/LabelHistoryTable";
import LabelHistoryOpenTabsHeader from "../../components/LabelHistoryOpenTabsHeader/LabelHistoryOpenTabsHeader";
import Format from "../../services/format";
import axios from "../../config/axios";
import "./label-history.scss";

const PAGE_TITLE = "Labelling History";
const NO_DATA_AVAILABLE_MSG = "No data available, go identify some fraud!";

// history table column header name to key value mapping
const LABEL_HISTORY_TABLE_HEADERS = {
  "": "",
  Time: "timestamp",
  Type: "id_type",
  ID: "id_value",
  "Time Frame": "table_date",
  "Estimated Fraud": "fraudulent_plays_rate",
  Action: "",
};
const DEFAULT_TABLE_SORT_FIELD = "timestamp";

class LabelHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateAccordionOpen: {},
      labelHistoryData: {},
      filteredLabelHistoryData: {},
      loading: true,
      searchIds: [],
      searchIndex: [],
      searchFilter: "",
      selectedCheckboxes: {},
      selectedLinks: [],
    };
  }

  componentDidMount = async () => {
    let pageData = {};
    let searchIds = [];
    let searchIndex = [];
    let dateAccordions = {};
    let selectedCheckboxes = {};

    try {
      const response = await axios.get("/get-labeling-history", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
        },
      });

      // set page data and search index
      [pageData, searchIndex, searchIds] = this.parseLabelHistoryData(
        response.data.results
      );

      Object.keys(pageData).forEach((date, i) => {
        // set date accordions tracking
        dateAccordions[date] = i === 0 ? true : false;

        // set selected checkboxes tracking
        pageData[date].forEach((entry) => {
          if (!selectedCheckboxes[date]) selectedCheckboxes[date] = {};
          const id = entry.id_value + entry.timestamp;

          // set checkbox link
          let link = "";
          if (entry.id_type === "song") {
            link = `/${entry.id_type}/${Format.formatSongUrl(
              // making some confident assumptions here with incoming id format
              ...Format.getSongNameAndArtist(entry.id_value)
            )}/${entry.table_date}?id=${entry.id_value_hashed}`;
          } else {
            link = `/${entry.id_type}/${entry.id_value}/${entry.table_date}`;
          }

          selectedCheckboxes[date][id] = {
            selected: false,
            link,
          };
        });
      });
    } catch (error) {
      // do nothing, just display no data message
    } finally {
      this.setState({
        dateAccordionOpen: dateAccordions,
        labelHistoryData: pageData,
        filteredLabelHistoryData: pageData,
        loading: false,
        searchIds,
        searchIndex,
        selectedCheckboxes,
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.searchFilter !== this.state.searchFilter) {
      await this.handleSearchFilterChange(this.state.searchFilter);
    }
  };

  parseLabelHistoryData = (labelHistoryData) => {
    const parsedData = {};
    const searchIds = [];
    const searchIndex = new Index({
      tokenize: "full",
    });

    labelHistoryData.forEach((entry, index) => {
      const date = Format.formatLabelHistoryDateObject(
        new Date(entry.timestamp)
      );

      if (parsedData[date]) {
        parsedData[date].push(entry);
      } else {
        parsedData[date] = [entry];
      }

      // add entry ID to search index
      searchIndex.add(index, entry.id_value);
      searchIds.push(entry.id_value);
    });

    return [parsedData, searchIndex, searchIds];
  };

  dateToday = (date) => {
    const today = new Date();
    const dateObj = new Date(date);

    return (
      today.getFullYear() === dateObj.getFullYear() &&
      today.getMonth() === dateObj.getMonth() &&
      today.getDate() === dateObj.getDate()
    );
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  handleDateAccordionClick = (date) => {
    this.setState({
      dateAccordionOpen: {
        ...this.state.dateAccordionOpen,
        [date]: !this.state.dateAccordionOpen[date],
      },
    });
  };

  isCheckboxSelected = (tableDate, id) => {
    return this.state.selectedCheckboxes[tableDate]?.[id]?.selected;
  };

  handleCheckboxClick = (tableDate, e) => {
    this.setState((prevState) => {
      const selectedCheckboxes = {
        ...prevState.selectedCheckboxes,
        [tableDate]: {
          ...prevState.selectedCheckboxes[tableDate],
          [e.target.value]: {
            ...prevState.selectedCheckboxes[tableDate][e.target.value],
            selected:
              !prevState.selectedCheckboxes[tableDate][e.target.value].selected,
          },
        },
      };

      const selectedLinks = [...prevState.selectedLinks];
      const targetCheckboxInfo = selectedCheckboxes[tableDate][e.target.value];

      if (targetCheckboxInfo.selected) {
        selectedLinks.push(targetCheckboxInfo.link);
      } else {
        const linkIndex = selectedLinks.indexOf(targetCheckboxInfo.link);
        selectedLinks.splice(linkIndex, 1);
      }

      return {
        selectedCheckboxes,
        selectedLinks,
      };
    });
  };

  clearSelectedCheckboxes = () => {
    const setADatesCheckboxesToFalse = (checkboxesDuringADate) => {
      const checkboxesAllSetToFalse = { ...checkboxesDuringADate };
      for (const id in checkboxesDuringADate) {
        checkboxesAllSetToFalse[id].selected = false;
      }
      return checkboxesAllSetToFalse;
    };

    // Set all checkbox selected states to false
    this.setState((prevState) => {
      const selectedCheckboxes = {};
      for (const tableDate in prevState.selectedCheckboxes) {
        selectedCheckboxes[tableDate] = setADatesCheckboxesToFalse(
          prevState.selectedCheckboxes[tableDate]
        );
      }
      return {
        selectedCheckboxes,
        selectedLinks: [],
      };
    });
  };

  handleSearchBarChange = (searchFilter) => {
    this.setState({ searchFilter });
  };

  handleSearchFilterChange = async (searchFilter) => {
    let dateAccordionOpen = {};

    if (searchFilter) {
      let filteredData = {};

      const searchResults = await this.state.searchIndex.search(searchFilter);
      const filteredResults = this.state.searchIds.filter((id, index) => {
        return searchResults.includes(index);
      });

      Object.keys(this.state.labelHistoryData).forEach((date) => {
        // open all accordions matching search results
        dateAccordionOpen[date] = true;

        this.state.labelHistoryData[date].forEach((entry) => {
          if (filteredResults.includes(entry.id_value)) {
            if (!filteredData[date]) {
              filteredData[date] = [entry];
            } else {
              filteredData[date].push(entry);
            }
          }
        });
      });

      this.setState({
        filteredLabelHistoryData: filteredData,
        dateAccordionOpen: dateAccordionOpen,
      });
    } else {
      Object.keys(this.state.labelHistoryData).forEach((date, index) => {
        // result all accordions to default position
        dateAccordionOpen[date] = index === 0 ? true : false;
      });

      const dataCopy = { ...this.state.labelHistoryData };
      this.setState({
        filteredLabelHistoryData: dataCopy,
        dateAccordionOpen: dateAccordionOpen,
      });
    }
  };

  render() {
    return (
      <div className={"label-history-container"}>
        <LabelHistoryOpenTabsHeader
          selectedLinks={this.state.selectedLinks}
          clearSelectedCheckboxes={this.clearSelectedCheckboxes}
        />
        <div className={"label-history-header"}>
          <div className={"label-history-back-button"}>
            <ArrowBackIosNewIcon
              className={"back-button-icon"}
              onClick={this.handleBackButtonClick}
            />
          </div>
          <div className={"logout-button-container"}>
            <Logout theme={"light"} />
          </div>
        </div>
        <div className={"label-history-body"}>
          <div className={"page-title-container"}>
            <div className={"page-title"}>{PAGE_TITLE}</div>
            <div className={"search-bar-container"}>
              <LabelHistorySearch handleSearch={this.handleSearchBarChange} />
            </div>
          </div>
          <div className={"page-body-container"}>
            {this.state.filteredLabelHistoryData &&
            Object.keys(this.state.filteredLabelHistoryData).length > 0 ? (
              <div>
                {Object.keys(this.state.filteredLabelHistoryData).map(
                  (date, i) => {
                    if (this.state.filteredLabelHistoryData[date].length > 0) {
                      return (
                        <Accordion
                          className={"date-accordion-container"}
                          expanded={this.state.dateAccordionOpen[date]}
                          onChange={(e) => this.handleDateAccordionClick(date)}
                          key={date}
                        >
                          <AccordionSummary
                            className={
                              this.state.dateAccordionOpen[date]
                                ? "date-accordion-button open"
                                : "date-accordion-button closed"
                            }
                            expandIcon={
                              <ExpandMoreIcon
                                className={"date-accordion-icon"}
                              />
                            }
                          >
                            <div className={"date-accordion-title"}>
                              {i === 0 && this.dateToday(date)
                                ? `Today - ${date}`
                                : date}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            className={"date-accordion-content"}
                          >
                            <LabelHistoryTable
                              tableDate={date}
                              data={this.state.filteredLabelHistoryData[date]}
                              headers={LABEL_HISTORY_TABLE_HEADERS}
                              defaultSortField={DEFAULT_TABLE_SORT_FIELD}
                              isCheckboxSelected={this.isCheckboxSelected}
                              onCheckboxClick={this.handleCheckboxClick}
                            />
                          </AccordionDetails>
                        </Accordion>
                      );
                    } else {
                      return <></>;
                    }
                  }
                )}
              </div>
            ) : !this.state.loading ? (
              <div className={"no-data-available"}>{NO_DATA_AVAILABLE_MSG}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default LabelHistory;
