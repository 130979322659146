import React, { Component } from "react";
import DashboardView from "../../containers/DashboardView/DashboardView";
import Auth from "../../services/auth";
import Util from "../../services/util";
import Format from "../../services/format";
import axios from "../../config/axios";
import "./login.scss";

class Login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      entityType: localStorage.getItem("entityType") || "user",
      loading: true,
      email: "",
      password: "",
      error: "",
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const authenticated = await Auth.checkUserAuthenticated();

    if (this._isMounted) {
      this.setState({
        loading: false,
      });

      if (authenticated) {
        await this.loadDashboardPage();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("/get-user", {
        email: this.state.email,
        password: this.state.password,
        useProductionClassifier:
          process.env.REACT_APP_USE_PRODUCTION_CLASSIFIER,
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("tk", token);
        await this.loadDashboardPage();
      }
    } catch (error) {
      this.setState({ error: "Error: Something went wrong" });
    }
  };

  loadDashboardPage = async () => {
    let entityId, metadata, tableDate;

    const localEntityId = localStorage.getItem(`${this.state.entityType}Id`);
    const localTableDate = localStorage.getItem(
      `${this.state.entityType}TableDate`
    );

    // regenerate session variables if any of them are missing
    if (
      !localEntityId ||
      localEntityId === "undefined" ||
      !localTableDate ||
      localTableDate === "undefined"
    ) {
      ({ entityId, metadata, tableDate } = await Util.getRandomEntity(
        this.state.entityType
        // TODO: Add provider?????
      ));

      // assign local storage variables
      localStorage.setItem(`${this.state.entityType}Id`, entityId);
      localStorage.setItem("entityType", this.state.entityType);
      localStorage.setItem(`${this.state.entityType}TableDate`, tableDate);
    } else {
      entityId = localEntityId;
      tableDate = localTableDate;

      if (this.state.entityType === "song") {
        metadata = await Util.getEntityMetadata(
          this.state.entityType,
          entityId,
          tableDate
        );
      }
    }

    if (this.props.history.location.state?.reason === "credsExpired") {
      // If login redirection was prompted by credentials timing out
      // Or an attempt to visit a specific url when not logged in
      // Restore the original url in state history
      this.props.history.replace({
        pathname: this.props.history.location.state.prevPathname,
      });
    } else {
      // redirect to profile page
      if (
        this.state.entityType === "user" ||
        this.state.entityType === "artist"
      ) {
        this.props.history.replace({
          pathname: `/${this.state.entityType}/${entityId}/${tableDate}`,
        });
      } else {
        this.props.history.replace({
          pathname: `/${this.state.entityType}/${Format.formatSongUrl(
            metadata?.track_title,
            metadata?.track_artist
          )}/${tableDate}?id=${entityId}`,
        });
      }
    }
  };

  render() {
    if (!this.state.loading) {
      return (
        <div className={"login-page"}>
          <div className={"login-form"}>
            <div className={"login-header"}>Login</div>
            <form
              className={"login-form-content"}
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <div className={"login-field"}>
                <label className={"login-label"}>Email:</label>
                <input
                  name={"email"}
                  type={"text"}
                  placeholder={"example@beatdapp.com"}
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className={"login-field"}>
                <label className={"login-label"}>Password:</label>
                <input
                  name={"password"}
                  type={"password"}
                  placeholder={"*******"}
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              {this.state.error && (
                <div className={"login-error"}>{this.state.error}</div>
              )}
              <input
                className={"login-submit"}
                type={"submit"}
                value={"LOGIN"}
              />
            </form>
          </div>
        </div>
      );
    }

    return <div className={"login-page"} />;
  }
}

export default DashboardView(Login);
