import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Loading from "../Loading/Loading";
import Format from "../../services/format";
import Util from "../../services/util";
import Card from "../../containers/Card/Card";
import { ReactComponent as SpotifyIcon } from "../../styles/icons/logo-spotify-min.svg";
import { ReactComponent as OpenTabIcon } from "../../styles/icons/open_tab.svg";
import axios from "../../config/axios";
import "./artist-monthly-listeners.scss";

const CARD_TITLE = "This DSP's Monthly Listeners";
const SPOTIFY_CTA_TEXT = "Find artist on Spotify ";
const MONTHLY_LISTENERS_NOTE =
  "Monthly listeners may be lower than total weekly users if the week starts and ends in different months.";

const ArtistMonthlyListeners = ({ artistId, tableDate, partnerName }) => {
  const [loading, setLoading] = useState(true);
  const [dspMonthlyListenersData, setDspMonthlyListenersData] = useState(null);
  const [spotifyLink, setSpotifyLink] = useState(undefined);

  const getArtistMonthlyListenersData = useCallback(
    async (artistId, tableDate, partnerName) => {
      if (artistId !== null && artistId.length) {
        try {
          const res = await axios.post(
            "/get-dsp-statistics",
            {
              entityType: "artist",
              entityID: artistId,
              tableDate: tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          // Populate with data from the API
          const results = res?.data?.results?.napster_monthly_listeners;
          setDspMonthlyListenersData(results);

          // Get Spotify link for the artist
          const newSpotifyLink = await Util.spotifySearchArtistHref(
            artistId,
            tableDate
          );
          setSpotifyLink(newSpotifyLink);
        } catch (error) {
          setDspMonthlyListenersData(null);
          setSpotifyLink(undefined);
        }

        // End loading state regardless
        setLoading(false);
      }
    },
    []
  );

  // Update component data on date or id change
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setLoading(true);
      getArtistMonthlyListenersData(artistId, tableDate, partnerName);
    }

    return () => {
      abort = true;
    };
  }, [artistId, tableDate, partnerName, getArtistMonthlyListenersData]);

  return (
    <Grid className={"artist-monthly-listeners-wrapper"} container>
      {!loading ? (
        <>
          <Grid className={"monthly-listeners-column"} item xs={6}>
            <div className={"monthly-listeners-title"}>
              <h3>{CARD_TITLE}</h3>
            </div>
            <div className={"monthly-listeners-desc"}>
              {MONTHLY_LISTENERS_NOTE}
            </div>
            <div className={"monthly-listeners-number-count"}>
              {typeof dspMonthlyListenersData === "number"
                ? `${Format.formatLargeNumber(dspMonthlyListenersData, 1)}`
                : "n/a"}
            </div>
          </Grid>
          <Grid className={"monthly-listeners-column"} item xs={6}>
            <div className={"spotify-green-background"} />
            <div className={"monthly-listeners-title"}>
              <SpotifyIcon className={"monthly-listeners-icon"} />
            </div>
            <a
              className={spotifyLink ? "" : "disabled"}
              href={spotifyLink}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <button className={"spotify-link-button"}>
                {SPOTIFY_CTA_TEXT}
                <OpenTabIcon />
              </button>
            </a>
          </Grid>
        </>
      ) : (
        <Loading />
      )}
    </Grid>
  );
};

export default Card(ArtistMonthlyListeners);
