import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import UseProfile from "../UseProfile/UseProfile";
import DashboardView from "../../containers/DashboardView/DashboardView";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import DashboardFooter from "../../components/DashboardFooter/DashboardFooter";
import StackedAreaChart from "../../components/StackedAreaChart/StackedAreaChart";
import PercentileMetrics from "../../components/PercentileMetrics/PercentileMetrics";
import SongDecisionBox from "../../components/SongDecisionBox/SongDecisionBox";
import Util from "../../services/util";
import Format from "../../services/format";

import { CARD_FRAUD_REASONS } from "../../config/song_fraud_reasons";

const ENTITY_TYPE = "song";

const SongProfile = (props) => {
  const [formattedEntityId, setFormattedEntityId] = useState(
    props.match.params.formatted_id
  );

  const generateChangedUrl = async (
    startDate,
    entityId,
    newFormattedEntityId
  ) => {
    return `/song/${
      newFormattedEntityId ?? formattedEntityId
    }/${startDate}?id=${entityId}`;
  };

  const {
    checkFraudReasonSelected,
    dataWeeks,
    editSelectedFraudReasons,
    endDate,
    entityId,
    generatePieChart,
    handleDateRangeChange,
    handlePartnerChange,
    loadPage,
    partnerName,
    pieChartsData,
    resetPage,
    selectedFraudReasons,
    startDate,
  } = UseProfile({
    ogEntityId: new URLSearchParams(props.location.search).get("id"),
    entityType: ENTITY_TYPE,
    formattedId: props.match.params.formatted_id,
    location: props.location,
    tableDateFromUrl: props.match.params.table_date,
    formattedEntityId,
    generateChangedUrl,
  });

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const updateFormattedEntityId = async () => {
        const metadata = await Util.getEntityMetadata(
          ENTITY_TYPE,
          entityId,
          startDate,
          partnerName
        );

        const newFormattedEntityId = Format.formatSongUrl(
          metadata?.track_title,
          metadata?.track_artist
        );
        setFormattedEntityId(newFormattedEntityId);
      };
      updateFormattedEntityId();
    }

    return () => {
      abort = true;
    };
  }, [entityId, startDate, partnerName]);

  return loadPage ? (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <DashboardHeader
          entityId={entityId}
          entityType={ENTITY_TYPE}
          partnerName={partnerName}
          startDate={startDate}
          endDate={endDate}
          dataWeeks={dataWeeks}
          handleDateRangeChange={handleDateRangeChange}
          handlePartnerChange={handlePartnerChange}
        />
      </Grid>
      <Grid item xs={12}>
        <StackedAreaChart
          entityId={entityId}
          entityType={ENTITY_TYPE}
          partnerName={partnerName}
          startDate={startDate}
          endDate={endDate}
          tableDate={startDate}
          dataWeeks={dataWeeks}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <PercentileMetrics
          entityType={ENTITY_TYPE}
          entityId={entityId}
          partnerName={partnerName}
          tableDate={startDate}
          title={"Percentile Metrics"}
          titleLength={250}
          cardFraudReasons={CARD_FRAUD_REASONS.percentileMetrics}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
        />
      </Grid>
      {pieChartsData.length > 0 &&
        pieChartsData.map((chartData) => {
          // TODO: render loading spinner
          return (
            <Grid item xs={4} key={`pie-chart-${chartData.id}`}>
              {generatePieChart({
                chartId: chartData.id,
                title: chartData.title,
                cardFraudReasons: CARD_FRAUD_REASONS?.pieChart?.[chartData.id],
                minHeight: 510,
              })}
            </Grid>
          );
        })}
      <Grid item xs={12}>
        <SongDecisionBox
          provider={partnerName}
          dsp={partnerName}
          entityId={entityId}
          tableDate={startDate}
          handleFormSubmission={resetPage}
          selectedFraudReasons={selectedFraudReasons}
          editSelectedFraudReasons={editSelectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <DashboardFooter />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default DashboardView(SongProfile);
