import React, { useState, useEffect, useCallback } from "react";
import Loading from "../Loading/Loading";
import PercentileMetric from "../PercentileMetric/PercentileMetric";
import Card from "../../containers/Card/Card";
import axios from "../../config/axios";
import "./artist-amplitude-events.scss";

const ArtistAmplitudeEvents = ({ artistId, tableDate, partnerName }) => {
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(null);
  const [percentile, setPercentile] = useState(null);
  const [zScore, setZScore] = useState(null);

  const getArtistAmplitudeEventsData = useCallback(
    async (artistId, tableDate, partnerName) => {
      if (artistId !== null && artistId.length) {
        try {
          const res = await axios.post(
            "/get-artist-user-amplitude-events",
            {
              entityType: "artist",
              entityID: artistId,
              tableDate: tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          // Populate with data from the API
          const results = res.data.results;
          setPercentile(results.zero_events_user_rate_percentile);
          setPercentage(results.zero_events_user_rate);
          setZScore(results.zero_events_user_rate_z_score);
        } catch (error) {
          setPercentile(null);
          setPercentage(null);
          setZScore(null);
        }

        // End loading state regardless
        setLoading(false);
      }
    },
    []
  );

  // Update component data on date or id change
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setLoading(true);
      getArtistAmplitudeEventsData(artistId, tableDate, partnerName);
    }

    return () => {
      abort = true;
    };
  }, [artistId, tableDate, partnerName, getArtistAmplitudeEventsData]);

  const formatPercentage = (percentage) => {
    const invalidValue = "n/a";

    try {
      if (
        percentage === null ||
        percentage === undefined ||
        typeof percentage !== "number"
      ) {
        return invalidValue;
      }

      const fixedPercentage = percentage.toFixed(2);

      // Get rid of a leading '0', if any
      return `${
        fixedPercentage[0] === "0" ? fixedPercentage.slice(1) : fixedPercentage
      }%`;
    } catch (err) {
      return invalidValue;
    }
  };

  return (
    <div className={"artist-amplitude-events-wrapper"}>
      <div
        className={
          loading
            ? "artist-amplitude-events-header loading"
            : "artist-amplitude-events-header"
        }
      >
        Amplitude Events
      </div>
      {!loading ? (
        <>
          <div className={"artist-amplitude-events-percentage"}>
            {formatPercentage(percentage)}
          </div>
          <div className={"artist-amplitude-events-sub-header"}>
            of users do <span className={"not-section"}>NOT</span> have
            Amplitude events
          </div>
          <div className={"artist-amplitude-events-percentile-metric"}>
            <PercentileMetric
              score={percentage}
              percentile={percentile}
              zScore={zScore}
              titleLength={0}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Card(ArtistAmplitudeEvents);
