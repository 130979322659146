import React, { useCallback, useEffect, useState, useRef } from "react";
import Colors from "../../services/colors";
import Format from "../../services/format";
import DrawSvgs from "../../services/draw_svgs";
import usePrevious from "../UsePrevious/UsePrevious";
import SelectFilter from "../SelectFilter/SelectFilter";
import TimelineToolTip from "../TimelineToolTip/TimelineToolTip";
import Loading from "../Loading/Loading";
import TimelineCanvas from "../TimelineCanvas/TimelineCanvas";
import { UseTimelineMarks } from "../UseTimelineMarks/UseTimelineMarks";
import { TIMELINE_CONSTANTS } from "../../config/timeline_fields";

import "./timeline.scss";

const TIMELINE_TITLE = "Events";
const CATEGORY_DROPDOWN_LABEL = "Group By: ";
const SUBCATEGORY_DROPDOWN_LABEL_PREFIX = "Show All ";
const NULL_REPLACEMENT_VALUE = "N/A";
const ONE_MIN_IN_MS = 60000;

const TIMELINE_HEIGHT = 100;
const TIMELINE_MARKER_HEIGHT = TIMELINE_HEIGHT / 2;
const TIMELINE_THICKNESS = 5;
const EVENT_MARKER_WIDTH = 1;
const EVENT_MARKER_WIDTH_BREAKPOINTS = [
  {
    minZoom: 1,
    markerThickness: 1,
  },
  {
    minZoom: 5,
    markerThickness: 2,
  },
  {
    minZoom: 15,
    markerThickness: 3,
  },
  {
    minZoom: 30,
    markerThickness: 4,
  },
];

const EVENT_MARKER_Y_POS = Math.round(
  TIMELINE_HEIGHT / 2 - TIMELINE_MARKER_HEIGHT / 2
);
const HOVER_MARKER_HEIGHT = TIMELINE_MARKER_HEIGHT * 1.25;
const HOVER_MARKER_SENSITIVITY_PX = 5;

// keeps the tooltip from accidentally blocking hovering over event markers
const TOOL_TIP_Y_OFFSET = TIMELINE_MARKER_HEIGHT / 2;
const TOOL_TIP_X_OFFSET = TIMELINE_THICKNESS;

// Increase to slow down the rate of hoverIndex changing
const MOUSE_STOP_DELAY = 10;
// Keeps timeline from flashing when scrolling across tiny gaps in plays
const END_HOVER_DELAY = 50;

const DEFAULT_GREY = "#e4e2e7";

const adjustedEventMarkerWidth = (zoomLevel) => {
  let markerWidth = EVENT_MARKER_WIDTH;

  for (const { minZoom, markerThickness } of EVENT_MARKER_WIDTH_BREAKPOINTS) {
    if (zoomLevel >= minZoom) {
      markerWidth = markerThickness;
    }
  }

  return markerWidth;
};

const Timeline = (props) => {
  const thisTypeSpecificLogic =
    TIMELINE_CONSTANTS[props.timelineType]?.typeSpecificLogic;

  const [timelineData, setTimelineData] = useState([]);
  const [usedColors, setUsedColors] = useState([]);
  const [selectedField, setSelectedField] = useState(
    thisTypeSpecificLogic.defaultCategoryFilter
  );
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedSubcategoryList, setSelectedSubcategoryList] = useState([]);
  const [subcategoryGroup, setSubcategoryGroup] = useState(undefined);
  const [subcategorySelected, setSubcategorySelected] = useState(false);

  const prevSelectedField = usePrevious(selectedField);
  const prevSelectedDay = usePrevious(props.selectedDay);

  const [colorValueMap, setColorValueMap] = useState({});
  const [positionValueArr, setPositionValueArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timelineEmpty, setTimelineEmpty] = useState(false);
  const [timelineWidth, setTimelineWidth] = useState(1);
  const prevTimelineWidth = usePrevious(timelineWidth);
  const [eventMarkerWidth, setEventMarkerWidth] = useState(
    adjustedEventMarkerWidth(props.widthMultiplier)
  );

  const parentRef = useRef(null);
  const scrollRef = useRef(null);

  // For tooltip
  const [playEventHover, setPlayEventHover] = useState(false);
  const [clientXPosition, setClientXPosition] = useState(0);
  const [clientYPosition, setClientYPosition] = useState(0);
  const [flipPosition, setFlipPosition] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(undefined);

  const [eventMarkers, setEventMarkers, canvasRef] = UseTimelineMarks(
    timelineWidth,
    TIMELINE_HEIGHT,
    eventMarkerWidth,
    TIMELINE_MARKER_HEIGHT,
    playEventHover
  );

  const prevEventMarkers = usePrevious(eventMarkers);
  const [greyEventMarkers, setGreyEventMarkers, greyCanvasRef] =
    UseTimelineMarks(
      timelineWidth,
      TIMELINE_HEIGHT,
      eventMarkerWidth,
      TIMELINE_MARKER_HEIGHT
    );

  // Message to render instead of the timeline if there's no data
  const noDataMessage = (timelineType) => {
    return `No ${
      timelineType === "amplitude" ? timelineType : "DSP"
    } data available`;
  };

  // Capitalize first letter of the type from state
  const timelineType =
    props.timelineType.charAt(0).toUpperCase() + props.timelineType.slice(1);

  const checkSelectedDay = (eventTime, currentSelectedDay) => {
    const eventDate = new Date(Format.formatUTCDate(eventTime));
    const selectedDate = new Date(currentSelectedDay);
    return (
      eventDate.getUTCDate() === selectedDate.getUTCDate() &&
      eventDate.getUTCMonth() === selectedDate.getUTCMonth()
    );
  };

  const mouseHoverTimerRef = React.useRef(null);

  const getValue = (playEvent, field) => {
    let value;
    if (playEvent) {
      if (field === "stream_duration") {
        value =
          // Check for null or undefined values first
          playEvent[field] === null || playEvent[field] === undefined
            ? NULL_REPLACEMENT_VALUE
            : playEvent[field] < 30
            ? "Less than 30 seconds"
            : playEvent[field] <= 60
            ? "30-60 seconds"
            : "Greater than 60 seconds";
      } else if (
        field === "device_type" &&
        props.timelineType !== "amplitude" // Avoid breaking Amplitude device_type values
      ) {
        let deviceType = [];
        value = playEvent[field];
        // Check for null or undefined device type
        if (value !== undefined && value !== null) {
          for (const word of value.split("_")) {
            // regex to keep from adding 'words' with numbers, aka versioning
            if (!/\d/.test(word)) {
              deviceType.push(word);
            }
          }
          value = deviceType.join(" ");
        }
      } else {
        value = playEvent[field];
      }

      // Return N/A if value is undefined or null
      return value ?? NULL_REPLACEMENT_VALUE;
    }
  };

  const handleEventMarkerHover = useCallback(
    (event, grey) => {
      if (!props.renderStickyTooltip) {
        mouseHoverTimerRef.current && clearTimeout(mouseHoverTimerRef.current);
        const checkIfNoHoverMarker = () =>
          !hoveredMarker?.index && hoveredMarker?.index !== 0;

        let hoveredMarker = eventMarkers.find(
          (marker) => marker.x === event?.nativeEvent?.layerX
        );

        if (checkIfNoHoverMarker()) {
          // Find the first valid marker with the right x value, if any
          hoveredMarker = greyEventMarkers.find(
            (marker) =>
              marker.x === event?.nativeEvent?.layerX &&
              (subcategorySelected === false || selectedGroup === marker.value)
          );

          // If no exact match assigned above, widen search parameters
          // To make it easier to hover on less clustered markers
          if (checkIfNoHoverMarker()) {
            hoveredMarker = greyEventMarkers.find((marker) => {
              // Find the first valid marker with x value within the marker sensitivity range
              if (
                subcategorySelected === false ||
                selectedGroup === marker.value
              ) {
                const markerXMinusEventX =
                  marker.x - event?.nativeEvent?.layerX;
                const validRight =
                  markerXMinusEventX < HOVER_MARKER_SENSITIVITY_PX;
                // Factor in event marker width
                const validLeft =
                  markerXMinusEventX >
                  -(HOVER_MARKER_SENSITIVITY_PX + (eventMarkerWidth - 1));
                return validLeft && validRight;
              } else {
                return false;
              }
            });
          }
        }

        mouseHoverTimerRef.current = setTimeout(
          () => {
            // Check if there's already a subcategory
            if (
              subcategorySelected === false ||
              hoveredMarker?.value === selectedGroup
            ) {
              setHoverIndex(hoveredMarker?.index);

              if (subcategorySelected === false) {
                // N/A string in the case of the value being null
                setSelectedGroup(
                  hoveredMarker?.value !== null
                    ? hoveredMarker?.value
                    : NULL_REPLACEMENT_VALUE
                );
              }
            }

            if (!grey) {
              const validHoverMarker =
                hoveredMarker !== undefined &&
                (subcategorySelected === false ||
                  hoveredMarker?.value === selectedGroup);
              setPlayEventHover(validHoverMarker ? true : false);
            }
          },
          !checkIfNoHoverMarker() ? MOUSE_STOP_DELAY : END_HOVER_DELAY
        );
      }
    },
    [
      eventMarkers,
      greyEventMarkers,
      eventMarkerWidth,
      selectedGroup,
      subcategorySelected,
      props.renderStickyTooltip,
    ]
  );

  const generateSubcategoryDefaultOption = (selectedField) => {
    const defaultSubcategory =
      SUBCATEGORY_DROPDOWN_LABEL_PREFIX +
      Format.pluralizeText(
        TIMELINE_CONSTANTS[props.timelineType]?.selectFilterOptions?.find(
          (option) => {
            return option.field === selectedField;
          }
        ).label
      );

    return {
      label: defaultSubcategory,
      field: undefined,
    };
  };

  const generateSubcategoryList = (playEvents, selectedField) => {
    const subCategoryList = [];
    const subCategoryPresentValues = [];

    if (
      TIMELINE_CONSTANTS[props.timelineType]?.fieldsWithSubcategories?.includes(
        selectedField
      )
    ) {
      for (const playEvent of playEvents) {
        const value = getValue(playEvent, selectedField);
        if (subCategoryPresentValues.indexOf(value) === -1) {
          subCategoryPresentValues.push(value);
          const label = TIMELINE_CONSTANTS[
            props.timelineType
          ]?.subcategoriesToRegexAnonymize?.includes(selectedField)
            ? Format.filterNamedPartners(value)
            : value;
          subCategoryList.push({
            label: label ?? NULL_REPLACEMENT_VALUE,
            field: value ?? NULL_REPLACEMENT_VALUE,
          });
        }
      }

      // Sort the list alphabetically, then add the default 'Show All' option to the start
      subCategoryList
        .sort((a, b) => `${a.label}`.localeCompare(b.label))
        .unshift(generateSubcategoryDefaultOption(selectedField));
    }

    return subCategoryList;
  };

  const handleEventMarkerClick = () => {
    // Only set sticky tooltip if there is an event marker being hovered over
    if (hoverIndex !== undefined) {
      props.setStickyTooltipName(props.timelineType);
      props.setStickyTooltipIndex(hoverIndex);
      props.setStickyTooltipActive(typeof hoverIndex === "number");
    }
  };

  const handleCloseIconClick = () => {
    props.setStickyTooltipName("");
    props.setStickyTooltipIndex(undefined);
    props.setStickyTooltipActive(false);
  };

  const calculateTimelineWidth = useCallback(() => {
    const width = Math.round(
      parentRef?.current?.parentNode?.getBoundingClientRect()?.width *
        (props.widthMultiplier ? props.widthMultiplier : 1)
    );
    return width ? width : 1;
  }, [props.widthMultiplier]);

  const generateEventMarkers = useCallback(
    (playEvents, startDate, endDate, usedColors, makeGreyCopy = false) => {
      // Fixes the initial render
      const currentWidth = timelineWidth
        ? timelineWidth
        : Math.round(parentRef?.current?.getBoundingClientRect()?.width);

      const startTime = new Date(
        (!props.selectedDay ? startDate : props.selectedDay) + "T00:00:00"
      );
      const endTime = new Date(
        (!props.selectedDay ? endDate : props.selectedDay) + "T23:59:59"
      );
      const timeRange = (endTime - startTime) / ONE_MIN_IN_MS;
      let localColorValueMap = {};

      const generateMarkers = (greyCopy) => {
        // generates 2 canvases with event indicators, one colored with full functionality
        // the other (underneath) grey with limited functionality
        // the grey one is reset less often than the coloured
        // only makes as many coloured ones as required/that fit the filter requirements
        const coloredMarkers = [];
        const greyMarkers = [];
        const positions = [];

        playEvents.forEach((playEvent, index) => {
          const value = getValue(playEvent, selectedField);
          // NULL_REPLACEMENT_VALUE check to handle falsy, 'null' value as a potential grouping
          const drawColoredMarker =
            value === selectedGroup ||
            (!subcategorySelected &&
              (selectedGroup === undefined ||
                (!value && selectedGroup === NULL_REPLACEMENT_VALUE)));

          // Check whether any markers are going to drawn before running calculations
          if (greyCopy || drawColoredMarker) {
            let adjustedOffset;
            // If making the background grey layer move
            // That means also recalculating/remapping positions
            // AKA updating the positionValueArr
            if (greyCopy) {
              const playTimestampStr =
                playEvent[thisTypeSpecificLogic.timestampField];

              // Must format the date to avoid problems with Firefox
              const playDate = new Date(Format.formatUTCDate(playTimestampStr));

              const minutesPastStart =
                (playDate - startTime) / ONE_MIN_IN_MS +
                playDate.getTimezoneOffset();

              const percentToLeft = (100 / timeRange) * minutesPastStart;
              // Turns percentage to px as required by Canvas
              const offset =
                ((currentWidth -
                  (TIMELINE_THICKNESS + eventMarkerWidth / 2) * 2) *
                  percentToLeft) /
                100;
              // Takes the thickness of the lines into account
              adjustedOffset = Math.round(offset + TIMELINE_THICKNESS);
              positions.push(adjustedOffset);
            } else {
              adjustedOffset = positionValueArr[index];
            }
            let colorToUse = "";

            if (!colorValueMap[selectedField]) {
              colorValueMap[selectedField] = {};
            } else {
              localColorValueMap = colorValueMap[selectedField];
            }

            // color already has been set on previous iteration and should render
            if (localColorValueMap[value]) {
              colorToUse = localColorValueMap[value];
              // color has not been set yet but should render
            } else {
              localColorValueMap[value] = usedColors[index];
              colorToUse = usedColors[index];
            }
            if (
              !props.selectedDay ||
              checkSelectedDay(
                playEvent[thisTypeSpecificLogic.dateFilterField],
                props.selectedDay
              )
            ) {
              if (drawColoredMarker) {
                coloredMarkers.push({
                  key: value + index,
                  value: value,
                  index: index,
                  x: adjustedOffset,
                  y: EVENT_MARKER_Y_POS,
                  color: colorToUse,
                  hoverHeight: HOVER_MARKER_HEIGHT,
                  hoverIndex,
                });
              }

              if (greyCopy) {
                greyMarkers.push({
                  key: value + index + "grey",
                  value: value,
                  index: index,
                  x: adjustedOffset,
                  y: EVENT_MARKER_Y_POS,
                  color: DEFAULT_GREY,
                });
              }
            }
          }
        });

        setColorValueMap((previousColorValueMap) => ({
          ...previousColorValueMap,
          [selectedField]: localColorValueMap,
        }));

        if (greyCopy) {
          setPositionValueArr(positions);
          return { greyMarkers, coloredMarkers };
        } else {
          return coloredMarkers;
        }
      };

      const fullEventMarkers = generateMarkers(makeGreyCopy);

      return fullEventMarkers;
    },
    // handles startDate|endDate warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      timelineWidth,
      props.selectedDay,
      props.startDate,
      props.endDate,
      props.timelineType,
      selectedField,
      selectedGroup,
      colorValueMap,
      positionValueArr,
      hoverIndex,
    ]
  );

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const resetStates = () => {
        setSubcategorySelected(false);
        setSubcategoryGroup(undefined);
        setSelectedGroup(undefined);
        setLoading(true);
        setTimelineData([]);
        setUsedColors([]);
        setColorValueMap({});
        setPositionValueArr([]);
        setTimelineEmpty(false);
        setPlayEventHover(false);
        setClientXPosition(0);
        setClientYPosition(0);
        setFlipPosition(null);
        setHoverIndex(undefined);
        setSelectedField(thisTypeSpecificLogic.defaultCategoryFilter);
        setSelectedSubcategoryList(
          generateSubcategoryList(
            [],
            thisTypeSpecificLogic.defaultCategoryFilter
          )
        );
      };

      const getTimelineData = async (newTimelineData, startDate, endDate) => {
        if (newTimelineData) {
          if (!newTimelineData?.length) {
            setTimelineEmpty(true);
            setEventMarkers([]);
            setGreyEventMarkers([]);
          }

          const assignedColors = [];
          for (let i = 0; i < newTimelineData.length; i++) {
            const color =
              Colors.getDefaultColors()[i] ||
              Colors.generateUnusedColor(assignedColors);
            assignedColors.push(color);
          }

          setTimelineData(newTimelineData);
          setSelectedSubcategoryList(
            generateSubcategoryList(newTimelineData, selectedField)
          );
          setUsedColors(assignedColors);
          const fullEventMarkers = generateEventMarkers(
            newTimelineData,
            startDate,
            endDate,
            assignedColors,
            true
          );

          setEventMarkers(fullEventMarkers.coloredMarkers);
          setGreyEventMarkers(fullEventMarkers.greyMarkers);
        }
        setLoading(false);
      };

      // resets various states to their default starting values
      resetStates();
      // processes the new timeline data
      getTimelineData(props.timelineData, props.startDate, props.endDate);
    }

    return () => {
      abort = true;
    };

    // handles generateEventMarkers warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timelineData, props.startDate, props.endDate]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      if (
        ((prevTimelineWidth && prevTimelineWidth !== timelineWidth) ||
          (prevSelectedField && prevSelectedField !== selectedField) ||
          (prevSelectedDay !== undefined &&
            prevSelectedDay !== props.selectedDay)) &&
        !timelineEmpty
      ) {
        setLoading(true);
        if (!props.renderStickyTooltip && !subcategorySelected) {
          setSelectedGroup(undefined);
        } else if (prevSelectedField && prevSelectedField !== selectedField) {
          // Update the selectedGroup of a sticky tooltip for an event marker
          // To be that event marker's value for the new selectedField
          setSelectedGroup(
            getValue(timelineData?.[props.stickyTooltipIndex], selectedField)
          );
        }
        const fullEventMarkers = generateEventMarkers(
          timelineData,
          props.startDate,
          props.endDate,
          usedColors,
          true
        );
        setEventMarkers(fullEventMarkers.coloredMarkers);
        setGreyEventMarkers(fullEventMarkers.greyMarkers);
        setLoading(false);
      }
    }

    return () => {
      abort = true;
    };

    // handles startDate|endDate warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedDay,
    prevSelectedDay,
    prevSelectedField,
    selectedField,
    generateEventMarkers,
    timelineData,
    usedColors,
    timelineEmpty,
    prevTimelineWidth,
    timelineWidth,
    setEventMarkers,
    setGreyEventMarkers,
  ]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      if (!loading && eventMarkers.length) setLoading(true);
      setEventMarkers(
        generateEventMarkers(
          timelineData,
          props.startDate,
          props.endDate,
          usedColors
        )
      );
    }

    return () => {
      abort = true;
    };

    // we already have a useEffect resetting the eventMarkers when
    // 'generateEventMarkers', 'timelineData' or 'usedColors' changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, hoverIndex]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (
      !abort &&
      prevEventMarkers?.length &&
      prevEventMarkers !== eventMarkers
    ) {
      setLoading(false);
    }

    return () => {
      abort = true;
    };
  }, [prevEventMarkers, eventMarkers]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      // Reset hover aspects of the tooltip on the stickyTooltipName changing
      if (props.stickyTooltipName !== props.timelineType) {
        setPlayEventHover(false);
        setHoverIndex(undefined);
        if (!subcategorySelected) {
          setSelectedGroup(undefined);
        }
      }
    }

    return () => {
      abort = true;
    };
  }, [props.stickyTooltipName, props.timelineType, subcategorySelected]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setLoading(props.retrievingData);
    }

    return () => {
      abort = true;
    };
  }, [props.retrievingData]);

  useEffect(() => {
    const resizeTimeline = () => {
      setTimelineWidth(calculateTimelineWidth());
      // Only resize scrollbar if timeline has data and therefore a width
      if (timelineData?.length && timelineData.length > 0) {
        props.setScrollBarWidth(
          parentRef?.current?.parentNode?.getBoundingClientRect()?.width
        );
        setEventMarkerWidth(adjustedEventMarkerWidth(props.widthMultiplier));
      }
    };

    window.addEventListener("resize", resizeTimeline);
    resizeTimeline();
    return () => {
      window.removeEventListener("resize", resizeTimeline);
    };

    // Following the linter's warning throws error due to asynchronicity
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widthMultiplier, calculateTimelineWidth, props.setScrollBarWidth]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (
      !abort &&
      props.widthMultiplier &&
      parentRef?.current?.parentNode?.getBoundingClientRect()?.width
    ) {
      setTimelineWidth(calculateTimelineWidth());
    }

    return () => {
      abort = true;
    };
  }, [props.widthMultiplier, parentRef, calculateTimelineWidth]);

  const handleTimelineScroll = (event) => {
    // Math.ceil instead of Math.round to keep it from jittering back and forth
    const newScrollLeft = Math.ceil(event.target.scrollLeft);
    if (newScrollLeft !== props.prevTimelineScrollLeft) {
      props.setTimelineScrollLeft(newScrollLeft);
    }
  };

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      // Update timeline not being scrolled on to match
      if (
        typeof props.timelineScrollLeft === "number" &&
        props.timelineScrollLeft !== scrollRef?.current?.scrollLeft &&
        scrollRef.current !== null
      ) {
        scrollRef.current.scrollLeft = props.timelineScrollLeft;
      }
    }

    return () => {
      abort = true;
    };
  }, [props.timelineScrollLeft]);

  const drawTimelineSvg = (timelineWidth, color) => {
    const startPos = 0;
    const timelineHeightWithThickness =
      startPos + TIMELINE_HEIGHT + TIMELINE_THICKNESS / 2;
    const timelineThicknessOffset = startPos + TIMELINE_THICKNESS;
    const midBarPosition =
      TIMELINE_HEIGHT / 2 - TIMELINE_THICKNESS / 2 + startPos;
    const midBarPosWithThickness = midBarPosition + TIMELINE_THICKNESS;
    const midBarLength = timelineWidth - TIMELINE_THICKNESS;
    const dPath = `M ${startPos} ${timelineHeightWithThickness} L ${startPos} ${startPos} L ${timelineThicknessOffset} ${startPos} L ${timelineThicknessOffset} ${midBarPosition} L ${midBarLength} ${midBarPosition} L ${midBarLength} ${startPos} L ${timelineWidth} ${startPos} L ${timelineWidth} ${timelineHeightWithThickness} L ${midBarLength} ${timelineHeightWithThickness} L ${midBarLength} ${midBarPosWithThickness} L ${timelineThicknessOffset} ${midBarPosWithThickness} L ${timelineThicknessOffset} ${timelineHeightWithThickness} L ${startPos} ${timelineHeightWithThickness} Z`;
    return DrawSvgs.drawCustomSvgPath(
      dPath,
      timelineWidth,
      TIMELINE_HEIGHT,
      color
    );
  };

  const handleTooltipLocation = (e) => {
    if (!props.grey) {
      setClientYPosition(e.clientY + TOOL_TIP_Y_OFFSET);

      // check if cursor is on the left or right side of the page
      if (e?.pageX > window.innerWidth / 2) {
        setFlipPosition(true);
        setClientXPosition(
          Math.round(window.innerWidth - e.clientX + TOOL_TIP_X_OFFSET)
        ); // measure from right
      } else {
        setFlipPosition(false);
        setClientXPosition(Math.round(e.clientX + TOOL_TIP_X_OFFSET));
      }
    }
  };

  return (
    <div className={"timeline-container"}>
      {!timelineEmpty ? (
        <>
          <div className={"timeline-type"}>
            <div className={"timeline-dropdown-container"}>
              <h3 className={"timeline-title"}>{`${
                timelineType !== "Amplitude" ? "DSP" : timelineType
              } ${TIMELINE_TITLE}`}</h3>
              <div className={"timeline-dropdowns"}>
                <SelectFilter
                  setSelectedField={(newField) => {
                    setSelectedField(newField);
                    setSelectedGroup(undefined);
                    setSubcategoryGroup(undefined);
                    setSubcategorySelected(false);
                    setSelectedSubcategoryList(
                      generateSubcategoryList(timelineData, newField)
                    );
                  }}
                  selectedField={selectedField}
                  filterOptions={
                    TIMELINE_CONSTANTS[props.timelineType]?.selectFilterOptions
                  }
                  label={CATEGORY_DROPDOWN_LABEL}
                />
                {TIMELINE_CONSTANTS[
                  props.timelineType
                ]?.fieldsWithSubcategories?.includes(selectedField) && (
                  <SelectFilter
                    setSelectedField={(newGroup) => {
                      if (props.renderStickyTooltip) {
                        props.setStickyTooltipActive(false);
                        setPlayEventHover(false);
                      }
                      setSelectedGroup(newGroup);
                      setSubcategoryGroup(newGroup);
                      setSubcategorySelected(
                        newGroup !== undefined ? true : false
                      );
                    }}
                    selectedField={subcategoryGroup}
                    filterOptions={selectedSubcategoryList}
                    label={""}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={"timeline"}
            ref={scrollRef}
            onScroll={(e) => handleTimelineScroll(e)}
          >
            <div className={"timeline-scroll"}>
              <div
                ref={parentRef}
                style={{
                  height: TIMELINE_HEIGHT,
                  width: calculateTimelineWidth(),
                }}
              >
                <TimelineCanvas
                  height={TIMELINE_HEIGHT}
                  width={timelineWidth}
                  markerWidth={eventMarkerWidth}
                  markerHeight={TIMELINE_MARKER_HEIGHT}
                  canvasRef={greyCanvasRef}
                  handleEventMarkerHover={handleEventMarkerHover}
                  grey={true}
                  allowHoverEvents={!props.renderStickyTooltip}
                />
                <TimelineCanvas
                  height={TIMELINE_HEIGHT}
                  width={timelineWidth}
                  markerWidth={eventMarkerWidth}
                  markerHeight={TIMELINE_MARKER_HEIGHT}
                  canvasRef={canvasRef}
                  handleEventMarkerHover={handleEventMarkerHover}
                  setPlayEventHover={setPlayEventHover}
                  handleTooltipLocation={handleTooltipLocation}
                  playEventHover={playEventHover}
                  handleEventMarkerClick={handleEventMarkerClick}
                  allowHoverEvents={!props.renderStickyTooltip}
                />
                {drawTimelineSvg(timelineWidth, "black")}
              </div>
            </div>
            {loading && <Loading />}

            <TimelineToolTip
              display={playEventHover | props.renderStickyTooltip}
              playEvent={
                timelineData[
                  props.renderStickyTooltip
                    ? props.stickyTooltipIndex
                    : hoverIndex
                ]
              }
              selectedField={selectedField}
              flipPosition={flipPosition}
              x={clientXPosition}
              y={clientYPosition}
              timelineType={props.timelineType}
              columnsCount={thisTypeSpecificLogic.toolTipColumnCount}
              includeCloseIcon={props.renderStickyTooltip}
              handleCloseIconClick={handleCloseIconClick}
              renderStickyTooltip={props.renderStickyTooltip}
              hiddenFields={thisTypeSpecificLogic.tooltipFilterFields}
            />
          </div>
        </>
      ) : (
        <div className={"timeline-no-data-message"}>
          {noDataMessage(props.timelineType)}
        </div>
      )}
    </div>
  );
};

export default Timeline;
