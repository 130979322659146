import React, { useState, useEffect } from "react";
import Loading from "../Loading/Loading";
import Card from "../../containers/Card/Card";
import Format from "../../services/format";
import styles from "../../styles/constants.scss";
import axios from "../../config/axios";
import "./artist-total-plays.scss";

const NO_DATA = "n/a";
const SUSPICIOUS_PERCENT_TEXT = "of total plays are suspicious";
const MONETIZED_PERCENT_TEXT = "of total plays are monetized";

// suspicious plays percentage metric bounds
const DEFAULT_BOUND = 5;
const LOW_BOUND = 20;
const MED_BOUND = 50;
const HIGH_BOUND = 100;

const ArtistTotalPlays = ({
  artistId,
  tableDate,
  setSusPlaysPercent,
  partnerName,
}) => {
  const [loading, setLoading] = useState(true);
  const [suspiciousPlays, setSuspiciousPlays] = useState({});
  const [monetizedPlays, setMonetizedPlays] = useState({});
  const [totalPlays, setTotalPlays] = useState(null);
  const [susPlaysColor, setSusPlaysColor] = useState(styles.defaultMetricColor);

  const setSusPlaysColorFromPercentage = (percentage) => {
    let newSusPlaysColor = styles.defaultMetricColor;

    if (percentage) {
      if (percentage > DEFAULT_BOUND && percentage <= LOW_BOUND) {
        newSusPlaysColor = styles.yellowMetricColor;
      } else if (percentage > LOW_BOUND && percentage <= MED_BOUND) {
        newSusPlaysColor = styles.redMetricColor;
      } else if (percentage > MED_BOUND && percentage <= HIGH_BOUND) {
        newSusPlaysColor = styles.darkRedMetricColor;
      }
    }

    setSusPlaysColor(newSusPlaysColor);
  };

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const getArtistTotalPlaysData = async () => {
        try {
          const response = await axios.post(
            "/get-total-plays",
            {
              entityType: "artist",
              entityID: artistId,
              tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          const resData = response.data.results;

          if (resData) {
            // build data objects
            const suspiciousPlaysData = {
              total:
                resData?.suspicious_users_plays !== undefined &&
                resData?.suspicious_users_plays !== null
                  ? resData.suspicious_users_plays
                  : NO_DATA,
              percentage:
                resData?.suspicious_users_plays_rate !== undefined &&
                resData?.suspicious_users_plays_rate !== null
                  ? Format.formatPercentage(resData.suspicious_users_plays_rate)
                  : NO_DATA,
              percentile:
                resData?.suspicious_users_plays_rate_percentile !== undefined &&
                resData?.suspicious_users_plays_rate_percentile !== null
                  ? Format.formatPercentile(
                      resData.suspicious_users_plays_rate_percentile
                    )
                  : NO_DATA,
              z_score:
                resData?.suspicious_users_plays_rate_z_score !== undefined &&
                resData?.suspicious_users_plays_rate_z_score !== null
                  ? Format.toDecimals(
                      resData.suspicious_users_plays_rate_z_score,
                      2
                    )
                  : NO_DATA,
            };

            // Update suspicious plays percentage for ArtistDecisionBox
            setSusPlaysPercent(suspiciousPlaysData.percentage);

            const monetizedPlaysData = {
              total:
                resData?.revenue_generating_streams !== undefined &&
                resData?.revenue_generating_streams !== null
                  ? resData.revenue_generating_streams
                  : NO_DATA,
              percentage:
                resData?.ratio_revenue_generating_streams !== undefined &&
                resData?.ratio_revenue_generating_streams !== null
                  ? Format.formatPercentage(
                      resData.ratio_revenue_generating_streams
                    )
                  : NO_DATA,
              percentile:
                resData?.ratio_revenue_generating_streams_percentile !==
                  undefined &&
                resData?.ratio_revenue_generating_streams_percentile !== null
                  ? Format.formatPercentile(
                      resData.ratio_revenue_generating_streams_percentile
                    )
                  : NO_DATA,
              z_score:
                resData?.ratio_revenue_generating_streams_z_score !==
                  undefined &&
                resData?.ratio_revenue_generating_streams_z_score !== null
                  ? Format.toDecimals(
                      resData.ratio_revenue_generating_streams_z_score,
                      2
                    )
                  : NO_DATA,
            };

            const totalPlaysData =
              resData?.total_plays !== undefined &&
              resData?.total_plays !== null
                ? resData.total_plays
                : NO_DATA;

            setLoading(false);
            setSuspiciousPlays(suspiciousPlaysData);
            setMonetizedPlays(monetizedPlaysData);
            setTotalPlays(totalPlaysData);

            // set component font color
            if (suspiciousPlaysData.percentage !== NO_DATA) {
              setSusPlaysColorFromPercentage(suspiciousPlaysData.percentage);
            }
          }
        } catch (error) {
          setLoading(false);
          setSuspiciousPlays({});
          setMonetizedPlays({});
          setTotalPlays(null);
        }
      };

      // reset component
      setLoading(true);
      setSuspiciousPlays({});
      setMonetizedPlays({});
      setTotalPlays(null);

      // retrieve data
      getArtistTotalPlaysData();
    }

    return () => {
      abort = true;
    };
  }, [artistId, tableDate, setSusPlaysPercent, partnerName]);

  return (
    <div className={"artist-total-plays-wrapper"}>
      {!loading ? (
        <>
          <div>
            <div className={"suspicious-plays-header"}>Suspicious Plays</div>
            <div
              className={"suspicious-plays-total"}
              style={{ color: susPlaysColor }}
            >
              {suspiciousPlays.total}
            </div>
            <div className={"percentage-metrics-container"}>
              <div
                className={"metric-percentage"}
                style={{ color: susPlaysColor }}
              >
                {`${
                  suspiciousPlays.percentage !== NO_DATA
                    ? `${suspiciousPlays.percentage}%`
                    : suspiciousPlays.percentage
                } ${SUSPICIOUS_PERCENT_TEXT}`}
              </div>
              <div className={"advanced-metric-section"}>
                {`${
                  suspiciousPlays.percentile !== NO_DATA
                    ? `${
                        suspiciousPlays.percentile +
                        Format.getPercentileSuffix(suspiciousPlays.percentile)
                      }`
                    : suspiciousPlays.percentile
                } percentile`}
              </div>
              <div className={"advanced-metric-section"}>
                {`z-score: ${suspiciousPlays.z_score}`}
              </div>
            </div>
          </div>
          <div className={"secondary-metric-container"}>
            <div className={"secondary-metric-header"}>Monetized Plays</div>
            <div className={"secondary-metric-total"}>
              {monetizedPlays.total}
            </div>
            <div className={"percentage-metrics-container"}>
              <div className={"metric-percentage"}>
                {`${
                  monetizedPlays.percentage !== NO_DATA
                    ? `${monetizedPlays.percentage}%`
                    : monetizedPlays.percentage
                } ${MONETIZED_PERCENT_TEXT}`}
              </div>
              <div className={"advanced-metric-section"}>
                {`${
                  monetizedPlays.percentile !== NO_DATA
                    ? `${
                        monetizedPlays.percentile +
                        Format.getPercentileSuffix(monetizedPlays.percentile)
                      }`
                    : monetizedPlays.percentile
                } percentile`}
              </div>
              <div className={"advanced-metric-section"}>
                {`z-score: ${monetizedPlays.z_score}`}
              </div>
            </div>
          </div>
          <div className={"secondary-metric-container"}>
            <div className={"secondary-metric-header"}>Total Plays</div>
            <div className={"secondary-metric-total"}>{totalPlays}</div>
          </div>
        </>
      ) : (
        <div className={"artist-total-plays-loader"}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Card(ArtistTotalPlays);
